
  export default {
    name: 'HeatMeterMarker',
    props: {
      marker: {
        type: String,
        default: 'primary'
      },
      color: {
        type: String,
        default: '#000000'
      }
    }
  }
