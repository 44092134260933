
import { CustomButton } from '@/components';
export default {
  name: 'Hero',
  components: { CustomButton },
  props: {
    blok: Object,
  },
  computed: {
    heroVariants() {
      return this.blok?.layout
    },
    // bgMobileImagePath() {
    //   const desktopImage = this.blok?.bg_image_desktop?.filename ?? ''
    //   return this.blok?.bg_image_mobile?.filename
    //     ? this.blok.bg_image_mobile.filename
    //     : desktopImage
    // }
  },
}
