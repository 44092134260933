
import { mapState } from 'vuex'

export default {
  name: 'CustomSection',
  props: {
    blok: Object,
  },
  computed: {
    ...mapState(['user']),
    canShow() {
      if(!this.blok?.visibility) return true;
      switch(this.blok?.visibility) {
        case 'logged-only':
          return Boolean(this.user);
        case 'not-logged-only':
          return !this.user;
        case 'aways':
          return true;  
        case 'hidden':
          return false;  
        default: 
          return true;
      }
    },
    sectionClasses() {
      return [
        this.blok.background_color, 
        this.blok.custom_css, 
        { 'detail': this.blok.add_details },
      ]
    }
  },
}
