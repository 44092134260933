
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'CustomProductCta',
  props: {
    placement: {
      type: String,
      default: 'menu',
      validator: (value) => {
        return ['menu', 'catering', 'order'].includes(value)
      }
    }
  },
  computed: {
    ...mapState([ 'location', 'cart' ]),
    ...mapState({
      choices: state => state.product.choices,
      isEdit: state => state.product.isEdit,
      product: state => state.product,
      data: state => state.product.data,
    }),
    actionText() {
      if(this.isEdit) {
        return 'Update Order'
      } else {
        return 'Add to Order'
      }
    },
    cost() {
      let cost = this.data.cost
      if (this.choices?.length) {
        this.choices.map(c => {
          cost += c.cost
        })
      }

      cost = cost * this.product.quantity
      
      return cost ? `$${cost.toFixed(2)}` : null
    },
  },
  methods: {
    ...mapActions([ 'viewCart' ]),
    ...mapActions({
      addProduct: 'product/addProduct',
      addCateringProduct: 'catering/addProduct',
      updateProduct: 'product/updateProduct',
      removeAllMods: 'product/removeAllMods',
    }),
    ...mapMutations([ 'setShowCart' ]),
    async handleClick() {
      if (this.cart) {
        if(this.$route.query.edit) {
          const productFound = this.cart.products.find((p, i) => {
            return p.product_id == this.$route.query.edit && i == this.$route.query.position
          })
          console.log(productFound)
          if (productFound) {
            const updatedProd = {
              ...productFound,
              choices: this.choices,
              quantity: this.product.quantity
            }
            const success = await this.updateProduct(updatedProd)
            if (success) {
              return this.openCartOnMenu()
            }
            return
          }
        }
      }
      let success = null
      if (this.$route.path.includes('catering')) {
        success = await this.addCateringProduct()
      } else {
        success = await this.addProduct()
      }
      if (success) {
        this.openCartOnMenu()
      }
    },
    openCartOnMenu() {
      document.body.style.overflow = 'hidden'
      this.setShowCart(true)
      this.viewCart(this.cart)
      this.$router.push(`/${this.placement}/${this.location.menu_id}`)
    },
  },
}
