
import { mapMutations, mapState, mapActions } from 'vuex'

// import Icon from '../../../common/Icon'

export default {
  name: 'CheckoutUserInfo',
  // components: { Icon },
  props: {
    config: Object,
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      errors: [],
    }
  },
  mounted() {
    if (this.guestUser) {
      this.first_name = this.guestUser.first_name
      this.last_name = this.guestUser.last_name
      this.email = this.guestUser.email
      this.phone = this.guestUser.phone
    } else if (this.user) {
      this.first_name = this.user.first_name
      this.last_name = this.user.last_name
      this.email = this.user.email
      this.phone = this.user.phone
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState({
      guestUser: state => state.checkout.guestUser
    }),
    provider() {
      return this.config?.provider ? this.config.provider : ''
    },
    icon() {
      return this.config?.icon ? this.config.icon : 'clock-icon'
    },
    title() {
      return this.config?.title ? this.config.title : 'Save Time at Checkout'
    },
    description() {
      return this.config?.description ? this.config.description : 'This information is required for ordering, but will not earn you loyalty points or rewards.'
    },
  },
  methods: {
    ...mapMutations(['setLoading', 'setErrors']),
    ...mapMutations({
      setGuestUser: 'checkout/setGuestUser',
    }),
    ...mapActions({
      updateUser: 'members/updateMember',
    }),
    validate() {
      let errors = []

      if (this.first_name && !this.first_name.match(/^[A-Za-z]+/)) {
        errors.push('first-name')
      }
      if (this.last_name && !this.last_name.match(/^[A-Za-z]+/)) {
        errors.push('last-name')
      }
      if (this.email && !this.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.push('email')
      }
      if (this.phone && !this.phone.replace(/[- )(]/g, '').match(/^\d{10}$/)) {
        errors.push('phone')
      }
      if (errors.length) {
        this.errors = errors
        this.setGuestUser(null)
      } else if (this.first_name && this.last_name && this.email && this.phone && !this.user) {
        this.setGuestUser({
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone.replace(/[- )(]/g, ''),
          type: 'guest'
        })
      }
    },
    async submit() {
      this.setLoading(true)
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone: this.phone.replace(/[- )(]/g, ''),
      }

      const response = await this.updateUser(data)
      if (response.status !== 'ok') {
        if (response.message) {
          this.setErrors(response.message)
        } else {
          this.setErrors(`We're unable to update your information, please try again later`)
        }
      }
      this.setLoading(false)
    },
  },
}
