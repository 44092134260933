
import { mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment'

import CheckoutOrder from '@/components/order/CheckoutOrder.vue'
import CustomUpsells from '@/components/common/CustomUpsells.vue'
import CustomSubmitPayment from '@/components/order/CustomSubmitPayment.vue'
import CheckoutUserInfo from '@/components/order/CheckoutUserInfo.vue'
import CheckoutOffers from '@/components/order/CheckoutOffers.vue'

import Coupon from '@pleinair/bigcolony-vue/components/cart/checkout/elements/Coupon.vue'
import Payments from '@pleinair/bigcolony-vue/components/cart/checkout/elements/Payments.vue'
import GiftCards from '@pleinair/bigcolony-vue/components/cart/checkout/elements/GiftCards.vue'
import CheckoutTips from '../../components/order/CheckoutTips.vue'
import CheckoutHeader from '../../components/order/CheckoutHeader.vue'
import CheckoutTotals from '@pleinair/bigcolony-vue/components/cart/checkout/elements/CheckoutTotals.vue'

export default {
  name: 'CheckoutPageWeb',
  components: {
    Coupon,
    Payments,
    GiftCards,
    CheckoutTips,
    CustomSubmitPayment,
    CheckoutOrder,
    CustomUpsells,
    CheckoutHeader,
    CheckoutTotals,
    CheckoutOffers,
    CheckoutUserInfo,
  },
  props: {
    data: {
      type: Object,
      deafult: () => {
        return {}
      }
    },
    sections: {
      // Override in Storyblok
      type: Array,
      default: () => [
        {
          id: 'payments',
          heading: 'Payment',
          copy: 'Add a Payment Method',
          visible: true,
          mobile_row: 'grid-row: 5;',
          desktop_row: 'grid-row: 1;',
          starts_open: true
        },
        {
          id: 'gift-cards',
          heading: 'Gift Cards',
          visible: true,
          mobile_row: 'grid-row: 4;',
          desktop_row: 'grid-row: 2;'
        },
        {
          id: 'coupons',
          heading: 'Coupons',
          visible: true,
          mobile_row: 'grid-row: 1;',
          desktop_row: 'grid-row: 3;'
        },
        {
          id: 'offers',
          heading: 'Offers',
          visible: true,
          mobile_row: 'grid-row: 2;',
          desktop_row: 'grid-row: 4;'
        },
        {
          id: 'upsells',
          heading: 'Upsells',
          visible: true,
          mobile_row: 'grid-row: 3;',
          desktop_row: 'grid-row: 5;'
        }
      ]
    },
    placement: {
      type: String,
      default: 'menu',
      validator: (value) => {
        return ['menu', 'catering', 'order'].includes(value)
      }
    },
    innerHeaderPlacement: Boolean,
    checkoutOrderTitleIcon: Boolean,
    checkoutOrderTitleIconName: String,
    customCheckoutHeader: Boolean,
    customCheckoutHeaderTitle: String,
    customCheckoutHeaderLeftAction: Object,
    customCheckoutHeaderRightAction: Object,
    customCheckoutLocationIcon: Object,
  },
  async beforeMount() {
    const hasGuestCheckout = this.getSection('user-info')
    if(!this.token && !hasGuestCheckout) {
      this.$router.push('/account/signin')
    }

    const promisses = []
    if (this.user && this.upsells && this.upsells.length === 0) {
      promisses.push(this.getCartUpsells())
    }
    if (this.user) {
      promisses.push(this.getCartRewards())
    }
    if (promisses.length > 0) {
      await Promise.all(promisses)
    }

    const invalidCart = await this.validateCart()
    if (invalidCart) {
      this.setErrors([ invalidCart ])
    }
  },
  computed: {
    ...mapState([ 'location', 'token', 'user', 'cart' ]),
    ...mapState({
      upsells: state => state.checkout.upsells,
      rewards: state => state.checkout.rewards,
      giftcards: state => state.checkout.giftcards,
      showConfirmLocationPopUp: state => state.checkout.showConfirmLocationPopUp,
      rewards_points: state => state.members.rewards.points,
    }),
    isMobile() {
      return this.$mq === 'sm' || this.$mq === 'md' ? true : false
    },
    offers() {
      let offers = []
      this?.rewards?.forEach(offer => {
        offers.push({
          applied: offer.applied,
          code: offer.code,
          description: offer.fineprint,
          expires: offer.expires,
          id: offer.id,
          images: offer.images,
          label: offer.label,
          member_id: offer.member_id,
          raw: offer.raw
        })
      })
      const sortedData = offers.sort((a, b) => {
        if (a.expires && !b.expires) {
          return -1
        }
        if (!a.expires && b.expires) {
          return 1
        }
        if (!a.expires && !b.expires) {
          return 0
        }

        const dateA = moment(a.expires, 'YYYYMMDD HH:mm').toDate()
        const dateB = moment(b.expires, 'YYYYMMDD HH:mm').toDate()

        return dateA - dateB
      })
      return sortedData
    },
    orderedRewards() {
      const data = [...this.rewards]
      const sortedData = data.sort((a, b) => {
        if (a.expires && !b.expires) {
          return -1
        }
        if (!a.expires && b.expires) {
          return 1
        }
        if (!a.expires && !b.expires) {
          return 0
        }

        const dateA = moment(a.expires, 'YYYYMMDD HH:mm').toDate()
        const dateB = moment(b.expires, 'YYYYMMDD HH:mm').toDate()

        return dateA - dateB
      })
      return sortedData
    },
  },
  methods: {
    ...mapActions(['trackEvent']),
    ...mapActions({
      validateCart: 'checkout/validateCart',
      getCartRewards: 'checkout/getCartRewards',
      getCartUpsells: 'checkout/getCartUpsells',
    }),
    ...mapMutations([ 'setErrors']),
    getSection(id) {
      return this.sections.find(section => section.id === id)
    },
    returnLink() {
      this.$router.push(`/${this.placement}/${this.cart?.menu_id}`)
    },
  },
  mounted() {
    this.trackEvent({
      name: 'begin_checkout',
      windowObject: window
    })
  },
}
