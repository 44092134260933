import { render, staticRenderFns } from "./CustomLocationSelected.vue?vue&type=template&id=0501b275&scoped=true&"
import script from "./CustomLocationSelected.vue?vue&type=script&lang=js&"
export * from "./CustomLocationSelected.vue?vue&type=script&lang=js&"
import style0 from "./CustomLocationSelected.vue?vue&type=style&index=0&id=0501b275&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0501b275",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIcon: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/Icon/index.vue').default,CommonHandoffModeSelector: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/HandoffModeSelector/index.vue').default,CommonOrderTimePicker: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/OrderTimePicker.vue').default})
