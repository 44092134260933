
import ProductModCounter from './ProductModCounter.vue'

import { mapState, mapActions } from 'vuex'
export default {
  name: 'CustomProductModifier',
  components: {
    ProductModCounter
  },
  props: {
    mod: {
      type: Object,
      default: () => {}
    },
    isVertical: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeOption: null,
      activeMods: [],
      chosen: null,
      showModBody: true,
    }
  },
  mounted() {
    this.isChosen()
  },
  computed: {
    ...mapState([ 'appInfo' ]),
    ...mapState({
      product: state => state.product,
      choices: state => state.product.choices,
      nutrition: state => state.app.nutrition
    }),
  },
  methods: {
    ...mapActions({
      selectMod: 'product/selectMod',
      removeMod: 'product/removeMod',
    }),
    async select(mod, id, qty) {
      let found = this.choices.find(choice => choice.id === mod.choice_id)
      if (found) {
        this.removeMod({ ...mod, id: id})
      } else {
        await this.selectMod({ ...mod, id: id, quantity: qty })
      }
      this.isChosen()
    },
    isChosen() {
      let choice = []
      this.mod.options.map(o => {
        this.choices.map(c => {
          if (c.id === o.choice_id) {
             choice.push(c)
          }
        })
      })
      if (choice?.length) {
        this.chosen = choice.reduce((acc, curr) => {
          acc.push(curr.name)
          return acc
        }, []).join(', ')
      } else {
        this.chosen = null
        this.showModBody = true
      }
    },
    async handleCounter(mod, id, qty) {
      if (qty < mod.quantities.min) {
        this.removeMod({ ...mod, id: id})
      } else {
        await this.selectMod({ ...mod, id: id, quantity: qty })
      }
    },
    selected(mod) {
      return this.choices.find(choice => choice.id === mod.choice_id)
    },
  },
  watch: {
    choices() {
      this.isChosen()
    }
  }
}
