
import { mapState } from 'vuex'
import ProductCounter from './ProductCounter.vue';

export default {
  name: 'CustomProductDescription',
  components: {
    ProductCounter
  },
  computed: {
    ...mapState({
      product: state => state.product,
      data: state => state.product.data,
      choices: state => state.product.choices
    }),
    cost() {
      let cost = this.data.cost
      if (this.choices?.length) {
        this.choices.map(c => {
          cost += c.cost
        })
      }

      cost = cost * this.product.quantity
      
      return `$${cost.toFixed(2)}`
    },
    isMobile() {
      return this.$mq == 'sm' ? true : false
    },
  }
}
