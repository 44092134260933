
import { mapState } from 'vuex'

import CustomSubmitPayment from '@/components/order/CustomSubmitPayment.vue'
import CheckoutTotals from '@pleinair/bigcolony-vue/components/cart/checkout/elements/CheckoutTotals.vue'

export default {
  name: 'CheckoutOrder',
  components: {
    CheckoutTotals,
    CustomSubmitPayment,
  },
  props: {
    showIcon: Boolean,
    iconName: String,
  },
  computed: {
    ...mapState([ 'appInfo', 'cart' ]),
    isMobile() {
      return this.$mq === 'sm' || this.$mq === 'md' ? true : false
    },
    shouldShowAddMoreItemsButton() {
      return this.appInfo?.checkout?.show_add_more_items_button
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submitPayment')
    }
  }
}
