// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap_iQetA{overflow:hidden}.content_H0xXU{width:100000px}.text_KVbOX{animation-iteration-count:infinite;animation-name:animation_ZhjiD;animation-timing-function:linear;float:left}.paused_HLufi .text_KVbOX{animation-play-state:paused}@keyframes animation_ZhjiD{0%{transform:translateX(0)}to{transform:translateX(-100%)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap_iQetA",
	"content": "content_H0xXU",
	"text": "text_KVbOX",
	"animation": "animation_ZhjiD",
	"paused": "paused_HLufi"
};
module.exports = ___CSS_LOADER_EXPORT___;
