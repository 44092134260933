
  import { mapState, mapActions, mapMutations } from 'vuex'
  
  import FormField from '../../components/globals/forms/FormField.vue'

  export default {
    name: 'CheckoutTips',
    components: {
      FormField,
    },
    data() {
      return {
        tips: ['0%', '10%', '15%', '20%', 'Custom'],
        edition: false,
        animation: false,
      }
    },
    computed: {
      ...mapState([ 'appInfo', 'cart' ]),
      ...mapState({
        tipSelected: state => state.checkout.tipSelected,
        applyDefaultTip: state => state.checkout.applyDefaultTip,
      }),
      allowTip() {
        if (this.cart && this.cart.tips) {
          return this.cart.tips.allow
        }

        return false
      },
      tipField() {
        return {
          name: 'tip',
          label: 'Tip amount',
          type: this.edition || this.animation ? 'number' : 'string',
          step: '0.01',
          min: '0.01',
          max: '999.99',
          placeholder: 'Enter Dollar Amount',
          value: this.cartTip ? this.cartTip : '',
          touched: false,
          error: null,
          errors: {
            invalid: 'Please enter a valid tip.'
          }
        }
      },
      cartTip() {
        if (this.cart && this.cart.totals) {
          return this.cart.totals.tip
        }
        return 0
      },
      removeLabel() {
        return this.tipSelected === 'Custom' ? 'Remove Custom Tip' : 'Remove Tip'
      },
      shouldShowNoTipOption() {
        return this.appInfo?.checkout?.no_tip_option
      }
    },
    mounted() {
      if (this.applyDefaultTip && this.cart && this.cart.tips.percentage && !this.cartTip) {
        this.applyTipOption(`${this.cart.tips.percentage}%`)
      } else {
        this.reversePercCalc()
      }
    },
    methods: {
      ...mapActions([ 'getCart' ]),
      ...mapActions({
        validateCart: 'checkout/validateCart',
        applyCartTip: 'checkout/applyCartTip',
      }),
      ...mapMutations([ 'setLoading', 'setErrors']),
      ...mapMutations({
        setTipSelected: 'checkout/setTipSelected',
        setApplyDefaultTip: 'checkout/setApplyDefaultTip',
      }),
      applyTipOption(tipOption) {
        this.setTipSelected(tipOption)

        if (tipOption === 'Custom') {
          return this.edition = true
        }

        const subtotal = this.cart.totals.subtotal
        const amount = (Number(tipOption.replace('%', '')) * subtotal) / 100

        this.applyTip(amount)
      },
      applyCustomTip() {
        if (this.tipField.value && this.tipField.error === null) {
          this.applyTip(this.tipField.value)
          this.animation = true

          // fades the Applied state
          setTimeout(() => {
            this.animation = false
          }, 3000)
        }
      },
      async applyTip(amount) {
        await this.applyCartTip(amount)
        let invalidCart = await this.validateCart()
        if (invalidCart) {
          this.setErrors([ invalidCart ])
        } else {
          if (amount === 0) {
            this.setTipSelected(null)
          }
          this.setApplyDefaultTip(false)
          this.edition = false
          await this.getCart()
        }
      },
      reversePercCalc() {
        if (this.cart && this.cartTip) {
          const subtotal = this.cart.totals.subtotal
          const tipFound = this.tips.find((tip) => {
            if (tip !== 'Custom') {
              const amount = ((Number(tip.replace('%', '')) * subtotal) / 100).toFixed(2)
              return amount == this.cartTip
            }
          })
          if (tipFound) {
            this.setTipSelected(tipFound)
          } else {
            this.setTipSelected('Custom')
          }
        }
      },
    }
  }
