export const Page = () => import('../../node_modules/@pleinair/bigcolony-vue/components/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const CateringContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/catering/Container.vue' /* webpackChunkName: "components/catering-container" */).then(c => wrapFunctional(c.default || c))
export const CommonAccordionSection = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/AccordionSection.vue' /* webpackChunkName: "components/common-accordion-section" */).then(c => wrapFunctional(c.default || c))
export const CommonAnchorCategory = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/AnchorCategory.vue' /* webpackChunkName: "components/common-anchor-category" */).then(c => wrapFunctional(c.default || c))
export const CommonAppLink = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/AppLink.vue' /* webpackChunkName: "components/common-app-link" */).then(c => wrapFunctional(c.default || c))
export const CommonCategorySearch = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/CategorySearch.vue' /* webpackChunkName: "components/common-category-search" */).then(c => wrapFunctional(c.default || c))
export const CommonDietaryFilters = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/DietaryFilters.vue' /* webpackChunkName: "components/common-dietary-filters" */).then(c => wrapFunctional(c.default || c))
export const CommonFilterDropDown = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/FilterDropDown.vue' /* webpackChunkName: "components/common-filter-drop-down" */).then(c => wrapFunctional(c.default || c))
export const CommonLocationReservation = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/LocationReservation.vue' /* webpackChunkName: "components/common-location-reservation" */).then(c => wrapFunctional(c.default || c))
export const CommonLocationResults = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/LocationResults.vue' /* webpackChunkName: "components/common-location-results" */).then(c => wrapFunctional(c.default || c))
export const CommonLocationSelected = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/LocationSelected.vue' /* webpackChunkName: "components/common-location-selected" */).then(c => wrapFunctional(c.default || c))
export const CommonLocationSelectedDelivery = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/LocationSelectedDelivery.vue' /* webpackChunkName: "components/common-location-selected-delivery" */).then(c => wrapFunctional(c.default || c))
export const CommonMultibrandMenu = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/MultibrandMenu.vue' /* webpackChunkName: "components/common-multibrand-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonNoCategoryResults = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/NoCategoryResults.vue' /* webpackChunkName: "components/common-no-category-results" */).then(c => wrapFunctional(c.default || c))
export const CommonOrderCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/OrderCard.vue' /* webpackChunkName: "components/common-order-card" */).then(c => wrapFunctional(c.default || c))
export const CommonOrderTimeChoose = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/OrderTimeChoose.vue' /* webpackChunkName: "components/common-order-time-choose" */).then(c => wrapFunctional(c.default || c))
export const CommonOrderTimePicker = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/OrderTimePicker.vue' /* webpackChunkName: "components/common-order-time-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonRichText = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/RichText.vue' /* webpackChunkName: "components/common-rich-text" */).then(c => wrapFunctional(c.default || c))
export const CommonSingleCategory = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/SingleCategory.vue' /* webpackChunkName: "components/common-single-category" */).then(c => wrapFunctional(c.default || c))
export const CommonSiteModal = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/SiteModal.vue' /* webpackChunkName: "components/common-site-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonTooltip = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/Tooltip.vue' /* webpackChunkName: "components/common-tooltip" */).then(c => wrapFunctional(c.default || c))
export const MenuContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/Container.vue' /* webpackChunkName: "components/menu-container" */).then(c => wrapFunctional(c.default || c))
export const MenuQuContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/QuContainer.vue' /* webpackChunkName: "components/menu-qu-container" */).then(c => wrapFunctional(c.default || c))
export const StoryblokButton = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Button.vue' /* webpackChunkName: "components/storyblok-button" */).then(c => wrapFunctional(c.default || c))
export const StoryblokCateringEstimator = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/CateringEstimator.vue' /* webpackChunkName: "components/storyblok-catering-estimator" */).then(c => wrapFunctional(c.default || c))
export const StoryblokCateringMenu = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/CateringMenu.vue' /* webpackChunkName: "components/storyblok-catering-menu" */).then(c => wrapFunctional(c.default || c))
export const StoryblokCateringMenuItem = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/CateringMenuItem.vue' /* webpackChunkName: "components/storyblok-catering-menu-item" */).then(c => wrapFunctional(c.default || c))
export const StoryblokCateringService = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/CateringService.vue' /* webpackChunkName: "components/storyblok-catering-service" */).then(c => wrapFunctional(c.default || c))
export const StoryblokCateringServices = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/CateringServices.vue' /* webpackChunkName: "components/storyblok-catering-services" */).then(c => wrapFunctional(c.default || c))
export const StoryblokCityLocations = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/CityLocations.vue' /* webpackChunkName: "components/storyblok-city-locations" */).then(c => wrapFunctional(c.default || c))
export const StoryblokContact = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Contact.vue' /* webpackChunkName: "components/storyblok-contact" */).then(c => wrapFunctional(c.default || c))
export const StoryblokContactLinks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/ContactLinks.vue' /* webpackChunkName: "components/storyblok-contact-links" */).then(c => wrapFunctional(c.default || c))
export const StoryblokDefaultBanner = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/DefaultBanner.vue' /* webpackChunkName: "components/storyblok-default-banner" */).then(c => wrapFunctional(c.default || c))
export const StoryblokDownloadApp = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/DownloadApp.vue' /* webpackChunkName: "components/storyblok-download-app" */).then(c => wrapFunctional(c.default || c))
export const StoryblokDrinkDeliveryBanner = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/DrinkDeliveryBanner.vue' /* webpackChunkName: "components/storyblok-drink-delivery-banner" */).then(c => wrapFunctional(c.default || c))
export const StoryblokFranchiseeContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/FranchiseeContainer.vue' /* webpackChunkName: "components/storyblok-franchisee-container" */).then(c => wrapFunctional(c.default || c))
export const StoryblokHero = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Hero.vue' /* webpackChunkName: "components/storyblok-hero" */).then(c => wrapFunctional(c.default || c))
export const StoryblokHeroGiftCards = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/HeroGiftCards.vue' /* webpackChunkName: "components/storyblok-hero-gift-cards" */).then(c => wrapFunctional(c.default || c))
export const StoryblokHeroLocation = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/HeroLocation.vue' /* webpackChunkName: "components/storyblok-hero-location" */).then(c => wrapFunctional(c.default || c))
export const StoryblokImaginationStationBanner = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/ImaginationStationBanner.vue' /* webpackChunkName: "components/storyblok-imagination-station-banner" */).then(c => wrapFunctional(c.default || c))
export const StoryblokInfoComponent = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/InfoComponent.vue' /* webpackChunkName: "components/storyblok-info-component" */).then(c => wrapFunctional(c.default || c))
export const StoryblokLocationMap = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/LocationMap.vue' /* webpackChunkName: "components/storyblok-location-map" */).then(c => wrapFunctional(c.default || c))
export const StoryblokLoyaltyProgramItem = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/LoyaltyProgramItem.vue' /* webpackChunkName: "components/storyblok-loyalty-program-item" */).then(c => wrapFunctional(c.default || c))
export const StoryblokMenuCta = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/MenuCta.vue' /* webpackChunkName: "components/storyblok-menu-cta" */).then(c => wrapFunctional(c.default || c))
export const StoryblokRewardCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/RewardCard.vue' /* webpackChunkName: "components/storyblok-reward-card" */).then(c => wrapFunctional(c.default || c))
export const StoryblokRewardsSection = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/RewardsSection.vue' /* webpackChunkName: "components/storyblok-rewards-section" */).then(c => wrapFunctional(c.default || c))
export const StoryblokSociForm = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/SociForm.vue' /* webpackChunkName: "components/storyblok-soci-form" */).then(c => wrapFunctional(c.default || c))
export const StoryblokSubscriptionsSection = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/SubscriptionsSection.vue' /* webpackChunkName: "components/storyblok-subscriptions-section" */).then(c => wrapFunctional(c.default || c))
export const StoryblokTeaser = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Teaser.vue' /* webpackChunkName: "components/storyblok-teaser" */).then(c => wrapFunctional(c.default || c))
export const StoryblokTimeline = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Timeline.vue' /* webpackChunkName: "components/storyblok-timeline" */).then(c => wrapFunctional(c.default || c))
export const StoryblokTitle = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Title.vue' /* webpackChunkName: "components/storyblok-title" */).then(c => wrapFunctional(c.default || c))
export const StoryblokTopSellers = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/TopSellers.vue' /* webpackChunkName: "components/storyblok-top-sellers" */).then(c => wrapFunctional(c.default || c))
export const AccountConfirmedContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/confirmed/Container.vue' /* webpackChunkName: "components/account-confirmed-container" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/Container.vue' /* webpackChunkName: "components/account-default-container" */).then(c => wrapFunctional(c.default || c))
export const AccountForgot = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/forgot/Forgot.vue' /* webpackChunkName: "components/account-forgot" */).then(c => wrapFunctional(c.default || c))
export const AccountForgotForm = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/forgot/ForgotForm.vue' /* webpackChunkName: "components/account-forgot-form" */).then(c => wrapFunctional(c.default || c))
export const AccountForgotHeader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/forgot/ForgotHeader.vue' /* webpackChunkName: "components/account-forgot-header" */).then(c => wrapFunctional(c.default || c))
export const AccountForm = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/settings/AccountForm.vue' /* webpackChunkName: "components/account-form" */).then(c => wrapFunctional(c.default || c))
export const AccountSettings = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/settings/AccountSettings.vue' /* webpackChunkName: "components/account-settings" */).then(c => wrapFunctional(c.default || c))
export const AccountSigninContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signin/Container.vue' /* webpackChunkName: "components/account-signin-container" */).then(c => wrapFunctional(c.default || c))
export const AccountSignin = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signin/Signin.vue' /* webpackChunkName: "components/account-signin" */).then(c => wrapFunctional(c.default || c))
export const AccountSigninCode = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signin/SigninCode.vue' /* webpackChunkName: "components/account-signin-code" */).then(c => wrapFunctional(c.default || c))
export const AccountSigninFooter = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signin/SigninFooter.vue' /* webpackChunkName: "components/account-signin-footer" */).then(c => wrapFunctional(c.default || c))
export const AccountSigninForm = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signin/SigninForm.vue' /* webpackChunkName: "components/account-signin-form" */).then(c => wrapFunctional(c.default || c))
export const AccountSigninHeader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signin/SigninHeader.vue' /* webpackChunkName: "components/account-signin-header" */).then(c => wrapFunctional(c.default || c))
export const AccountSignupContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signup/Container.vue' /* webpackChunkName: "components/account-signup-container" */).then(c => wrapFunctional(c.default || c))
export const AccountSignupSignUpLocCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signup/SignUpLocCard.vue' /* webpackChunkName: "components/account-signup-sign-up-loc-card" */).then(c => wrapFunctional(c.default || c))
export const AccountSignupForm = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signup/SignupForm.vue' /* webpackChunkName: "components/account-signup-form" */).then(c => wrapFunctional(c.default || c))
export const GlobalsFormsCheckbox = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/forms/Checkbox.vue' /* webpackChunkName: "components/globals-forms-checkbox" */).then(c => wrapFunctional(c.default || c))
export const GlobalsFormsDateSelect = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/forms/DateSelect.vue' /* webpackChunkName: "components/globals-forms-date-select" */).then(c => wrapFunctional(c.default || c))
export const GlobalsFormsDefaultForm = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/forms/DefaultForm.vue' /* webpackChunkName: "components/globals-forms-default-form" */).then(c => wrapFunctional(c.default || c))
export const GlobalsFormsFormField = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/forms/FormField.vue' /* webpackChunkName: "components/globals-forms-form-field" */).then(c => wrapFunctional(c.default || c))
export const GlobalsFormsInputDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/forms/InputDefault.vue' /* webpackChunkName: "components/globals-forms-input-default" */).then(c => wrapFunctional(c.default || c))
export const GlobalsFormsLocSelector = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/forms/LocSelector.vue' /* webpackChunkName: "components/globals-forms-loc-selector" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutCloseBtn = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/CloseBtn.vue' /* webpackChunkName: "components/globals-layout-close-btn" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/Container.vue' /* webpackChunkName: "components/globals-layout-container" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutErrorPage = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/ErrorPage.vue' /* webpackChunkName: "components/globals-layout-error-page" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutErrors = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/Errors.vue' /* webpackChunkName: "components/globals-layout-errors" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutLoader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/Loader.vue' /* webpackChunkName: "components/globals-layout-loader" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutRecaptcha = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/Recaptcha.vue' /* webpackChunkName: "components/globals-layout-recaptcha" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutSiteFooter = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/SiteFooter.vue' /* webpackChunkName: "components/globals-layout-site-footer" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutSiteOverlay = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/SiteOverlay.vue' /* webpackChunkName: "components/globals-layout-site-overlay" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutSitePopup = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/SitePopup.vue' /* webpackChunkName: "components/globals-layout-site-popup" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsAddIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/AddIcon.vue' /* webpackChunkName: "components/globals-vectors-add-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsAppleIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/AppleIcon.vue' /* webpackChunkName: "components/globals-vectors-apple-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsArrowDownIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ArrowDownIcon.vue' /* webpackChunkName: "components/globals-vectors-arrow-down-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsArrowLeftIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ArrowLeftIcon.vue' /* webpackChunkName: "components/globals-vectors-arrow-left-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsArrowRightIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ArrowRightIcon.vue' /* webpackChunkName: "components/globals-vectors-arrow-right-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsArrowUpIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ArrowUpIcon.vue' /* webpackChunkName: "components/globals-vectors-arrow-up-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsAwardIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/AwardIcon.vue' /* webpackChunkName: "components/globals-vectors-award-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsBagIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/BagIcon.vue' /* webpackChunkName: "components/globals-vectors-bag-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsBellFilledIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/BellFilledIcon.vue' /* webpackChunkName: "components/globals-vectors-bell-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsBellIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/BellIcon.vue' /* webpackChunkName: "components/globals-vectors-bell-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsBurgerMenuIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/BurgerMenuIcon.vue' /* webpackChunkName: "components/globals-vectors-burger-menu-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsCalendarIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/CalendarIcon.vue' /* webpackChunkName: "components/globals-vectors-calendar-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsCarat = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/Carat.vue' /* webpackChunkName: "components/globals-vectors-carat" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsCateringIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/CateringIcon.vue' /* webpackChunkName: "components/globals-vectors-catering-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsCheckIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/CheckIcon.vue' /* webpackChunkName: "components/globals-vectors-check-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsChevronDownIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ChevronDownIcon.vue' /* webpackChunkName: "components/globals-vectors-chevron-down-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsChevronLeftIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ChevronLeftIcon.vue' /* webpackChunkName: "components/globals-vectors-chevron-left-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsChevronRightIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ChevronRightIcon.vue' /* webpackChunkName: "components/globals-vectors-chevron-right-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsChevronUpIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ChevronUpIcon.vue' /* webpackChunkName: "components/globals-vectors-chevron-up-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsClockIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ClockIcon.vue' /* webpackChunkName: "components/globals-vectors-clock-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsCloseIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/CloseIcon.vue' /* webpackChunkName: "components/globals-vectors-close-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainCashewIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainCashewIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-cashew-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainCashewsIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainCashewsIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-cashews-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainDairyIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainDairyIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-dairy-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainEggIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainEggIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-egg-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainEggsIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainEggsIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-eggs-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainFishIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainFishIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-fish-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainGlutenIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainGlutenIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-gluten-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainMilkIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainMilkIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-milk-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainNutsIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainNutsIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-nuts-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainPeanutIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainPeanutIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-peanut-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainSesameIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainSesameIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-sesame-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainShellfishIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainShellfishIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-shellfish-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainSoyIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainSoyIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-soy-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainVeganIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainVeganIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-vegan-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainWalnutsIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainWalnutsIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-walnuts-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsContainWheatIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ContainWheatIcon.vue' /* webpackChunkName: "components/globals-vectors-contain-wheat-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsCouponIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/CouponIcon.vue' /* webpackChunkName: "components/globals-vectors-coupon-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsCrossIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/CrossIcon.vue' /* webpackChunkName: "components/globals-vectors-cross-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsCurbsideIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/CurbsideIcon.vue' /* webpackChunkName: "components/globals-vectors-curbside-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsDeliveryIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/DeliveryIcon.vue' /* webpackChunkName: "components/globals-vectors-delivery-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsDirectionsIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/DirectionsIcon.vue' /* webpackChunkName: "components/globals-vectors-directions-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsEditIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/EditIcon.vue' /* webpackChunkName: "components/globals-vectors-edit-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsExclamationIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ExclamationIcon.vue' /* webpackChunkName: "components/globals-vectors-exclamation-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsEyeIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/EyeIcon.vue' /* webpackChunkName: "components/globals-vectors-eye-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsFacebookFilledIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/FacebookFilledIcon.vue' /* webpackChunkName: "components/globals-vectors-facebook-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsFacebookIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/FacebookIcon.vue' /* webpackChunkName: "components/globals-vectors-facebook-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsFilterIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/FilterIcon.vue' /* webpackChunkName: "components/globals-vectors-filter-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsForkIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ForkIcon.vue' /* webpackChunkName: "components/globals-vectors-fork-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsGiftCardIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/GiftCardIcon.vue' /* webpackChunkName: "components/globals-vectors-gift-card-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsGoogleIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/GoogleIcon.vue' /* webpackChunkName: "components/globals-vectors-google-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsHeartFilledIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/HeartFilledIcon.vue' /* webpackChunkName: "components/globals-vectors-heart-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsHeartIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/HeartIcon.vue' /* webpackChunkName: "components/globals-vectors-heart-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsHideIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/HideIcon.vue' /* webpackChunkName: "components/globals-vectors-hide-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsHomeIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/HomeIcon.vue' /* webpackChunkName: "components/globals-vectors-home-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsInfoIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/InfoIcon.vue' /* webpackChunkName: "components/globals-vectors-info-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsInstagramIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/InstagramIcon.vue' /* webpackChunkName: "components/globals-vectors-instagram-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsLocationFilledIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/LocationFilledIcon.vue' /* webpackChunkName: "components/globals-vectors-location-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsLocationIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/LocationIcon.vue' /* webpackChunkName: "components/globals-vectors-location-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsLocationsFilledIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/LocationsFilledIcon.vue' /* webpackChunkName: "components/globals-vectors-locations-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsLogOutIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/LogOutIcon.vue' /* webpackChunkName: "components/globals-vectors-log-out-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsLogoAmex = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/LogoAmex.vue' /* webpackChunkName: "components/globals-vectors-logo-amex" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsLogoDiscover = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/LogoDiscover.vue' /* webpackChunkName: "components/globals-vectors-logo-discover" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsLogoGiftCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/LogoGiftCard.vue' /* webpackChunkName: "components/globals-vectors-logo-gift-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsLogoMasterCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/LogoMasterCard.vue' /* webpackChunkName: "components/globals-vectors-logo-master-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsLogoVisa = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/LogoVisa.vue' /* webpackChunkName: "components/globals-vectors-logo-visa" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsMinusIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/MinusIcon.vue' /* webpackChunkName: "components/globals-vectors-minus-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsNavigationFilledIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/NavigationFilledIcon.vue' /* webpackChunkName: "components/globals-vectors-navigation-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsNavigationIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/NavigationIcon.vue' /* webpackChunkName: "components/globals-vectors-navigation-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsNumeralIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/NumeralIcon.vue' /* webpackChunkName: "components/globals-vectors-numeral-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsOffersIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/OffersIcon.vue' /* webpackChunkName: "components/globals-vectors-offers-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsOrderIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/OrderIcon.vue' /* webpackChunkName: "components/globals-vectors-order-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPackCartIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PackCartIcon.vue' /* webpackChunkName: "components/globals-vectors-pack-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPaymentsIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PaymentsIcon.vue' /* webpackChunkName: "components/globals-vectors-payments-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPhoneIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PhoneIcon.vue' /* webpackChunkName: "components/globals-vectors-phone-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPickupIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PickupIcon.vue' /* webpackChunkName: "components/globals-vectors-pickup-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPizzaLFilledIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PizzaLFilledIcon.vue' /* webpackChunkName: "components/globals-vectors-pizza-l-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPizzaLIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PizzaLIcon.vue' /* webpackChunkName: "components/globals-vectors-pizza-l-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPizzaRFilledIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PizzaRFilledIcon.vue' /* webpackChunkName: "components/globals-vectors-pizza-r-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPizzaRIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PizzaRIcon.vue' /* webpackChunkName: "components/globals-vectors-pizza-r-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPizzaWFilledIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PizzaWFilledIcon.vue' /* webpackChunkName: "components/globals-vectors-pizza-w-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPizzaWIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PizzaWIcon.vue' /* webpackChunkName: "components/globals-vectors-pizza-w-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPlusCircle = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PlusCircle.vue' /* webpackChunkName: "components/globals-vectors-plus-circle" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsPlusIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/PlusIcon.vue' /* webpackChunkName: "components/globals-vectors-plus-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsProfileIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ProfileIcon.vue' /* webpackChunkName: "components/globals-vectors-profile-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsReservationIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ReservationIcon.vue' /* webpackChunkName: "components/globals-vectors-reservation-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsRewardsIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/RewardsIcon.vue' /* webpackChunkName: "components/globals-vectors-rewards-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsSearchIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/SearchIcon.vue' /* webpackChunkName: "components/globals-vectors-search-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsSettingsIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/SettingsIcon.vue' /* webpackChunkName: "components/globals-vectors-settings-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsShoppingBagIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ShoppingBagIcon.vue' /* webpackChunkName: "components/globals-vectors-shopping-bag-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsShoppingCartIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ShoppingCartIcon.vue' /* webpackChunkName: "components/globals-vectors-shopping-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsShowIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/ShowIcon.vue' /* webpackChunkName: "components/globals-vectors-show-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsStoreIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/StoreIcon.vue' /* webpackChunkName: "components/globals-vectors-store-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsTikTokIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/TikTokIcon.vue' /* webpackChunkName: "components/globals-vectors-tik-tok-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsTrashIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/TrashIcon.vue' /* webpackChunkName: "components/globals-vectors-trash-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsTwitterIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/TwitterIcon.vue' /* webpackChunkName: "components/globals-vectors-twitter-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsTwitterXIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/TwitterXIcon.vue' /* webpackChunkName: "components/globals-vectors-twitter-x-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsVerticalDotsIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/VerticalDotsIcon.vue' /* webpackChunkName: "components/globals-vectors-vertical-dots-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsWalletIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/WalletIcon.vue' /* webpackChunkName: "components/globals-vectors-wallet-icon" */).then(c => wrapFunctional(c.default || c))
export const CateringMenuContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/catering/menu/Container.vue' /* webpackChunkName: "components/catering-menu-container" */).then(c => wrapFunctional(c.default || c))
export const CateringSearchContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/catering/search/Container.vue' /* webpackChunkName: "components/catering-search-container" */).then(c => wrapFunctional(c.default || c))
export const CartAccountCheckoutAccountPage = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/account/CheckoutAccountPage.vue' /* webpackChunkName: "components/cart-account-checkout-account-page" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/Container.vue' /* webpackChunkName: "components/cart-checkout-container" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutQuCheckout = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/QuCheckout.vue' /* webpackChunkName: "components/cart-checkout-qu-checkout" */).then(c => wrapFunctional(c.default || c))
export const CartConfirmContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/confirm/Container.vue' /* webpackChunkName: "components/cart-confirm-container" */).then(c => wrapFunctional(c.default || c))
export const CommonAccordionApp = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/AccordionApp/index.vue' /* webpackChunkName: "components/common-accordion-app" */).then(c => wrapFunctional(c.default || c))
export const CommonAccordionItem = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/AccordionItem/index.vue' /* webpackChunkName: "components/common-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const CommonCart = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/Cart/index.vue' /* webpackChunkName: "components/common-cart" */).then(c => wrapFunctional(c.default || c))
export const CommonCartProduct = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/CartProduct/index.vue' /* webpackChunkName: "components/common-cart-product" */).then(c => wrapFunctional(c.default || c))
export const CommonCateringQuantitySelectorMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/CateringQuantitySelector/CateringQuantitySelector.mocks.js' /* webpackChunkName: "components/common-catering-quantity-selector-mocks" */).then(c => wrapFunctional(c.default || c))
export const CommonCateringQuantitySelector = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/CateringQuantitySelector/index.vue' /* webpackChunkName: "components/common-catering-quantity-selector" */).then(c => wrapFunctional(c.default || c))
export const CommonHandoffModeSelectorMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/HandoffModeSelector/HandoffModeSelector.mocks.js' /* webpackChunkName: "components/common-handoff-mode-selector-mocks" */).then(c => wrapFunctional(c.default || c))
export const CommonHandoffModeSelector = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/HandoffModeSelector/index.vue' /* webpackChunkName: "components/common-handoff-mode-selector" */).then(c => wrapFunctional(c.default || c))
export const CommonIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/Icon/index.vue' /* webpackChunkName: "components/common-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonLocationCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/LocationCard/index.vue' /* webpackChunkName: "components/common-location-card" */).then(c => wrapFunctional(c.default || c))
export const CommonLocationCurbsideForm = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/LocationCurbsideForm/index.vue' /* webpackChunkName: "components/common-location-curbside-form" */).then(c => wrapFunctional(c.default || c))
export const CommonLocationDeliveryForm = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/LocationDeliveryForm/index.vue' /* webpackChunkName: "components/common-location-delivery-form" */).then(c => wrapFunctional(c.default || c))
export const CommonLocationSearchMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/LocationSearch/LocationSearch.mocks.js' /* webpackChunkName: "components/common-location-search-mocks" */).then(c => wrapFunctional(c.default || c))
export const CommonLocationSearch = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/LocationSearch/index.vue' /* webpackChunkName: "components/common-location-search" */).then(c => wrapFunctional(c.default || c))
export const CommonOrderHeaderMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/OrderHeader/OrderHeader.mocks.js' /* webpackChunkName: "components/common-order-header-mocks" */).then(c => wrapFunctional(c.default || c))
export const CommonOrderHeader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/OrderHeader/index.vue' /* webpackChunkName: "components/common-order-header" */).then(c => wrapFunctional(c.default || c))
export const CommonRadarMap = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/RadarMap/index.vue' /* webpackChunkName: "components/common-radar-map" */).then(c => wrapFunctional(c.default || c))
export const CommonSavedAddress = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/SavedAddress/index.vue' /* webpackChunkName: "components/common-saved-address" */).then(c => wrapFunctional(c.default || c))
export const CommonSavedAddresses = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/SavedAddresses/index.vue' /* webpackChunkName: "components/common-saved-addresses" */).then(c => wrapFunctional(c.default || c))
export const CommonSavedCreditCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/SavedCreditCard/index.vue' /* webpackChunkName: "components/common-saved-credit-card" */).then(c => wrapFunctional(c.default || c))
export const CommonSimpleBoxMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/SimpleBox/SimpleBox.mocks.js' /* webpackChunkName: "components/common-simple-box-mocks" */).then(c => wrapFunctional(c.default || c))
export const CommonSimpleBox = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/SimpleBox/index.vue' /* webpackChunkName: "components/common-simple-box" */).then(c => wrapFunctional(c.default || c))
export const CommonSimpleMap = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/SimpleMap/index.vue' /* webpackChunkName: "components/common-simple-map" */).then(c => wrapFunctional(c.default || c))
export const CommonSingleProduct = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/SingleProduct/index.vue' /* webpackChunkName: "components/common-single-product" */).then(c => wrapFunctional(c.default || c))
export const CommonUpsells = () => import('../../node_modules/@pleinair/bigcolony-vue/components/common/Upsells/index.vue' /* webpackChunkName: "components/common-upsells" */).then(c => wrapFunctional(c.default || c))
export const LocationsAllContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/all/Container.vue' /* webpackChunkName: "components/locations-all-container" */).then(c => wrapFunctional(c.default || c))
export const LocationsSearchContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/search/Container.vue' /* webpackChunkName: "components/locations-search-container" */).then(c => wrapFunctional(c.default || c))
export const LocationsSingleContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/single/Container.vue' /* webpackChunkName: "components/locations-single-container" */).then(c => wrapFunctional(c.default || c))
export const MenuCategoryContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/category/Container.vue' /* webpackChunkName: "components/menu-category-container" */).then(c => wrapFunctional(c.default || c))
export const MenuIndexContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/index/Container.vue' /* webpackChunkName: "components/menu-index-container" */).then(c => wrapFunctional(c.default || c))
export const MenuProductContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/Container.vue' /* webpackChunkName: "components/menu-product-container" */).then(c => wrapFunctional(c.default || c))
export const MenuProductQuContainer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/QuContainer.vue' /* webpackChunkName: "components/menu-product-qu-container" */).then(c => wrapFunctional(c.default || c))
export const StoryblokCtaBlockMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/CtaBlock/CtaBlock.mocks.js' /* webpackChunkName: "components/storyblok-cta-block-mocks" */).then(c => wrapFunctional(c.default || c))
export const StoryblokCtaBlock = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/CtaBlock/index.vue' /* webpackChunkName: "components/storyblok-cta-block" */).then(c => wrapFunctional(c.default || c))
export const StoryblokFlyDealsMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/FlyDeals/FlyDeals.mocks.js' /* webpackChunkName: "components/storyblok-fly-deals-mocks" */).then(c => wrapFunctional(c.default || c))
export const StoryblokFlyDeals = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/FlyDeals/index.vue' /* webpackChunkName: "components/storyblok-fly-deals" */).then(c => wrapFunctional(c.default || c))
export const StoryblokGridMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Grid/Grid.mocks.js' /* webpackChunkName: "components/storyblok-grid-mocks" */).then(c => wrapFunctional(c.default || c))
export const StoryblokGrid = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Grid/index.vue' /* webpackChunkName: "components/storyblok-grid" */).then(c => wrapFunctional(c.default || c))
export const StoryblokInsiderRewardsBlockMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/InsiderRewardsBlock/InsiderRewardsBlock.mocks.js' /* webpackChunkName: "components/storyblok-insider-rewards-block-mocks" */).then(c => wrapFunctional(c.default || c))
export const StoryblokInsiderRewardsBlock = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/InsiderRewardsBlock/index.vue' /* webpackChunkName: "components/storyblok-insider-rewards-block" */).then(c => wrapFunctional(c.default || c))
export const StoryblokLocationHeroOperationInfo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/LocationHero/OperationInfo.vue' /* webpackChunkName: "components/storyblok-location-hero-operation-info" */).then(c => wrapFunctional(c.default || c))
export const StoryblokLocationHero = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/LocationHero/index.vue' /* webpackChunkName: "components/storyblok-location-hero" */).then(c => wrapFunctional(c.default || c))
export const StoryblokOrderAgainMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/OrderAgain/OrderAgain.mocks.js' /* webpackChunkName: "components/storyblok-order-again-mocks" */).then(c => wrapFunctional(c.default || c))
export const StoryblokOrderAgain = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/OrderAgain/index.vue' /* webpackChunkName: "components/storyblok-order-again" */).then(c => wrapFunctional(c.default || c))
export const StoryblokSectionMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Section/Section.mocks.js' /* webpackChunkName: "components/storyblok-section-mocks" */).then(c => wrapFunctional(c.default || c))
export const StoryblokSection = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/Section/index.vue' /* webpackChunkName: "components/storyblok-section" */).then(c => wrapFunctional(c.default || c))
export const StoryblokStoreDetailsAddressInfo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/StoreDetails/AddressInfo.vue' /* webpackChunkName: "components/storyblok-store-details-address-info" */).then(c => wrapFunctional(c.default || c))
export const StoryblokStoreDetailsPhoneInfo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/StoreDetails/PhoneInfo.vue' /* webpackChunkName: "components/storyblok-store-details-phone-info" */).then(c => wrapFunctional(c.default || c))
export const StoryblokStoreDetails = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/StoreDetails/index.vue' /* webpackChunkName: "components/storyblok-store-details" */).then(c => wrapFunctional(c.default || c))
export const StoryblokSubscriptionBlockMocks = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/SubscriptionBlock/SubscriptionBlock.mocks.js' /* webpackChunkName: "components/storyblok-subscription-block-mocks" */).then(c => wrapFunctional(c.default || c))
export const StoryblokSubscriptionBlock = () => import('../../node_modules/@pleinair/bigcolony-vue/components/storyblok/SubscriptionBlock/index.vue' /* webpackChunkName: "components/storyblok-subscription-block" */).then(c => wrapFunctional(c.default || c))
export const AccountConfirmedViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/confirmed/views/Default.vue' /* webpackChunkName: "components/account-confirmed-views-default" */).then(c => wrapFunctional(c.default || c))
export const AccountAlert = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountAlert.vue' /* webpackChunkName: "components/account-alert" */).then(c => wrapFunctional(c.default || c))
export const AccountBadgeCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountBadgeCard.vue' /* webpackChunkName: "components/account-badge-card" */).then(c => wrapFunctional(c.default || c))
export const AccountBody = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountBody.vue' /* webpackChunkName: "components/account-body" */).then(c => wrapFunctional(c.default || c))
export const AccountChallengeCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountChallengeCard.vue' /* webpackChunkName: "components/account-challenge-card" */).then(c => wrapFunctional(c.default || c))
export const AccountCustomError = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountCustomError.vue' /* webpackChunkName: "components/account-custom-error" */).then(c => wrapFunctional(c.default || c))
export const AccountHeader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountHeader.vue' /* webpackChunkName: "components/account-header" */).then(c => wrapFunctional(c.default || c))
export const AccountNav = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountNav.vue' /* webpackChunkName: "components/account-nav" */).then(c => wrapFunctional(c.default || c))
export const AccountRewardsMsg = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountRewardsMsg.vue' /* webpackChunkName: "components/account-rewards-msg" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultElementsAddOffer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AddOffer.vue' /* webpackChunkName: "components/account-default-elements-add-offer" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultElementsHowToRedeem = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/HowToRedeem.vue' /* webpackChunkName: "components/account-default-elements-how-to-redeem" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultElementsOfferCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/OfferCard.vue' /* webpackChunkName: "components/account-default-elements-offer-card" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultElementsSingleSignOn = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/elements/SingleSignOn.vue' /* webpackChunkName: "components/account-default-elements-single-sign-on" */).then(c => wrapFunctional(c.default || c))
export const AccountHistory = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountHistory.vue' /* webpackChunkName: "components/account-history" */).then(c => wrapFunctional(c.default || c))
export const AccountInfo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountInfo.vue' /* webpackChunkName: "components/account-info" */).then(c => wrapFunctional(c.default || c))
export const AccountLocations = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountLocations.vue' /* webpackChunkName: "components/account-locations" */).then(c => wrapFunctional(c.default || c))
export const AccountOffers = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountOffers.vue' /* webpackChunkName: "components/account-offers" */).then(c => wrapFunctional(c.default || c))
export const AccountPayments = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountPayments.vue' /* webpackChunkName: "components/account-payments" */).then(c => wrapFunctional(c.default || c))
export const AccountRewardsSingle = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountRewardsSingle.vue' /* webpackChunkName: "components/account-rewards-single" */).then(c => wrapFunctional(c.default || c))
export const AccountDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/views/Default.vue' /* webpackChunkName: "components/account-default" */).then(c => wrapFunctional(c.default || c))
export const AccountForgotViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/forgot/views/Default.vue' /* webpackChunkName: "components/account-forgot-views-default" */).then(c => wrapFunctional(c.default || c))
export const AccountSigninViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signin/views/Default.vue' /* webpackChunkName: "components/account-signin-views-default" */).then(c => wrapFunctional(c.default || c))
export const AccountSigninViewsWebview = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signin/views/Webview.vue' /* webpackChunkName: "components/account-signin-views-webview" */).then(c => wrapFunctional(c.default || c))
export const AccountSignupViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/signup/views/Default.vue' /* webpackChunkName: "components/account-signup-views-default" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutFooterOrderingFooter = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/footer/OrderingFooter.vue' /* webpackChunkName: "components/globals-layout-footer-ordering-footer" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutHeaderCartIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/header/CartIcon.vue' /* webpackChunkName: "components/globals-layout-header-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutHeaderLogo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/header/Logo.vue' /* webpackChunkName: "components/globals-layout-header-logo" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutHeaderMainNav = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/header/MainNav.vue' /* webpackChunkName: "components/globals-layout-header-main-nav" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutHeaderMenuIcon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/header/MenuIcon.vue' /* webpackChunkName: "components/globals-layout-header-menu-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutHeaderMobileNav = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/header/MobileNav.vue' /* webpackChunkName: "components/globals-layout-header-mobile-nav" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutHeaderNavLink = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/header/NavLink.vue' /* webpackChunkName: "components/globals-layout-header-nav-link" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutHeaderOrderingHeader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/header/OrderingHeader.vue' /* webpackChunkName: "components/globals-layout-header-ordering-header" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutHeaderSiteHeader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/header/SiteHeader.vue' /* webpackChunkName: "components/globals-layout-header-site-header" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLayoutViewsOrdering = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/layout/views/Ordering.vue' /* webpackChunkName: "components/globals-layout-views-ordering" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsCostaVidaCart = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/CostaVida/Cart.vue' /* webpackChunkName: "components/globals-vectors-costa-vida-cart" */).then(c => wrapFunctional(c.default || c))
export const GlobalsVectorsFlyingPieTrophy = () => import('../../node_modules/@pleinair/bigcolony-vue/components/_globals/vectors/FlyingPie/Trophy.vue' /* webpackChunkName: "components/globals-vectors-flying-pie-trophy" */).then(c => wrapFunctional(c.default || c))
export const CateringMenuViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/catering/menu/views/Default.vue' /* webpackChunkName: "components/catering-menu-views-default" */).then(c => wrapFunctional(c.default || c))
export const CateringHero = () => import('../../node_modules/@pleinair/bigcolony-vue/components/catering/search/elements/CateringHero.vue' /* webpackChunkName: "components/catering-hero" */).then(c => wrapFunctional(c.default || c))
export const CateringOccasion = () => import('../../node_modules/@pleinair/bigcolony-vue/components/catering/search/elements/CateringOccasion.vue' /* webpackChunkName: "components/catering-occasion" */).then(c => wrapFunctional(c.default || c))
export const CateringSearchViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/catering/search/views/Default.vue' /* webpackChunkName: "components/catering-search-views-default" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsAddPaymentButton = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/AddPaymentButton.vue' /* webpackChunkName: "components/cart-checkout-elements-add-payment-button" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutHeader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/CheckoutHeader.vue' /* webpackChunkName: "components/cart-checkout-header" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutOffers = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/CheckoutOffers.vue' /* webpackChunkName: "components/cart-checkout-offers" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutOrder = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/CheckoutOrder.vue' /* webpackChunkName: "components/cart-checkout-order" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutTips = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/CheckoutTips.vue' /* webpackChunkName: "components/cart-checkout-tips" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutTotals = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/CheckoutTotals.vue' /* webpackChunkName: "components/cart-checkout-totals" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutUserInfo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/CheckoutUserInfo.vue' /* webpackChunkName: "components/cart-checkout-user-info" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutUtensils = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/CheckoutUtensils.vue' /* webpackChunkName: "components/cart-checkout-utensils" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsCoupon = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/Coupon.vue' /* webpackChunkName: "components/cart-checkout-elements-coupon" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsCreditCards = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/CreditCards.vue' /* webpackChunkName: "components/cart-checkout-elements-credit-cards" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsCustomFields = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/CustomFields.vue' /* webpackChunkName: "components/cart-checkout-elements-custom-fields" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsGiftCards = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/GiftCards.vue' /* webpackChunkName: "components/cart-checkout-elements-gift-cards" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsGiftCardsForm = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/GiftCardsForm.vue' /* webpackChunkName: "components/cart-checkout-elements-gift-cards-form" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsGiftCardsNew = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/GiftCardsNew.vue' /* webpackChunkName: "components/cart-checkout-elements-gift-cards-new" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsGiftCardsStored = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/GiftCardsStored.vue' /* webpackChunkName: "components/cart-checkout-elements-gift-cards-stored" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsHouseAccounts = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/HouseAccounts.vue' /* webpackChunkName: "components/cart-checkout-elements-house-accounts" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsOffer = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/Offer.vue' /* webpackChunkName: "components/cart-checkout-elements-offer" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsOloCcsf = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/OloCcsf.vue' /* webpackChunkName: "components/cart-checkout-elements-olo-ccsf" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsPayInStore = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/PayInStore.vue' /* webpackChunkName: "components/cart-checkout-elements-pay-in-store" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsPayments = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/Payments.vue' /* webpackChunkName: "components/cart-checkout-elements-payments" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsSubmitPayment = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/SubmitPayment.vue' /* webpackChunkName: "components/cart-checkout-elements-submit-payment" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsTaxExempt = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/TaxExempt.vue' /* webpackChunkName: "components/cart-checkout-elements-tax-exempt" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutElementsWorldPay = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/elements/WorldPay.vue' /* webpackChunkName: "components/cart-checkout-elements-world-pay" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutViewsApp = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/views/App.vue' /* webpackChunkName: "components/cart-checkout-views-app" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/checkout/views/Default.vue' /* webpackChunkName: "components/cart-checkout-views-default" */).then(c => wrapFunctional(c.default || c))
export const CartConfirmViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/cart/confirm/views/Default.vue' /* webpackChunkName: "components/cart-confirm-views-default" */).then(c => wrapFunctional(c.default || c))
export const LocationsAllElementsLocationCard = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/all/elements/LocationCard.vue' /* webpackChunkName: "components/locations-all-elements-location-card" */).then(c => wrapFunctional(c.default || c))
export const LocationsAllViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/all/views/Default.vue' /* webpackChunkName: "components/locations-all-views-default" */).then(c => wrapFunctional(c.default || c))
export const LocationsSearchElementsAddressInfo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/search/elements/AddressInfo.vue' /* webpackChunkName: "components/locations-search-elements-address-info" */).then(c => wrapFunctional(c.default || c))
export const LocationsSearchElementsOperationInfo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/search/elements/OperationInfo.vue' /* webpackChunkName: "components/locations-search-elements-operation-info" */).then(c => wrapFunctional(c.default || c))
export const LocationsSearchElementsPhoneInfo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/search/elements/PhoneInfo.vue' /* webpackChunkName: "components/locations-search-elements-phone-info" */).then(c => wrapFunctional(c.default || c))
export const LocationsSearchElementsVehicleInfo = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/search/elements/VehicleInfo.vue' /* webpackChunkName: "components/locations-search-elements-vehicle-info" */).then(c => wrapFunctional(c.default || c))
export const LocationsSearchViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/search/views/Default.vue' /* webpackChunkName: "components/locations-search-views-default" */).then(c => wrapFunctional(c.default || c))
export const LocationsSingleViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/locations/single/views/Default.vue' /* webpackChunkName: "components/locations-single-views-default" */).then(c => wrapFunctional(c.default || c))
export const MenuCategoryViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/category/views/Default.vue' /* webpackChunkName: "components/menu-category-views-default" */).then(c => wrapFunctional(c.default || c))
export const MenuIndexViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/index/views/Default.vue' /* webpackChunkName: "components/menu-index-views-default" */).then(c => wrapFunctional(c.default || c))
export const MenuProductCTAs = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductCTAs.vue' /* webpackChunkName: "components/menu-product-c-t-as" */).then(c => wrapFunctional(c.default || c))
export const MenuProductConfiguration = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductConfiguration.vue' /* webpackChunkName: "components/menu-product-configuration" */).then(c => wrapFunctional(c.default || c))
export const MenuProductDescription = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductDescription.vue' /* webpackChunkName: "components/menu-product-description" */).then(c => wrapFunctional(c.default || c))
export const MenuProductHeader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductHeader.vue' /* webpackChunkName: "components/menu-product-header" */).then(c => wrapFunctional(c.default || c))
export const MenuProductImage = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductImage.vue' /* webpackChunkName: "components/menu-product-image" */).then(c => wrapFunctional(c.default || c))
export const MenuProductInstructions = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductInstructions.vue' /* webpackChunkName: "components/menu-product-instructions" */).then(c => wrapFunctional(c.default || c))
export const MenuProductModButtonGroup = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductModButtonGroup.vue' /* webpackChunkName: "components/menu-product-mod-button-group" */).then(c => wrapFunctional(c.default || c))
export const MenuProductModSelectCheck = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductModSelectCheck.vue' /* webpackChunkName: "components/menu-product-mod-select-check" */).then(c => wrapFunctional(c.default || c))
export const MenuProductModSelectCounter = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductModSelectCounter.vue' /* webpackChunkName: "components/menu-product-mod-select-counter" */).then(c => wrapFunctional(c.default || c))
export const MenuProductModSelector = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductModSelector.vue' /* webpackChunkName: "components/menu-product-mod-selector" */).then(c => wrapFunctional(c.default || c))
export const MenuProductModifier = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductModifier.vue' /* webpackChunkName: "components/menu-product-modifier" */).then(c => wrapFunctional(c.default || c))
export const MenuProductOption = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductOption.vue' /* webpackChunkName: "components/menu-product-option" */).then(c => wrapFunctional(c.default || c))
export const MenuProductSelections = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductSelections.vue' /* webpackChunkName: "components/menu-product-selections" */).then(c => wrapFunctional(c.default || c))
export const MenuProductSelectionsFooter = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductSelectionsFooter.vue' /* webpackChunkName: "components/menu-product-selections-footer" */).then(c => wrapFunctional(c.default || c))
export const MenuProductSelectionsHeader = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductSelectionsHeader.vue' /* webpackChunkName: "components/menu-product-selections-header" */).then(c => wrapFunctional(c.default || c))
export const MenuProductToggle = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/elements/ProductToggle.vue' /* webpackChunkName: "components/menu-product-toggle" */).then(c => wrapFunctional(c.default || c))
export const MenuProductViewsDefault = () => import('../../node_modules/@pleinair/bigcolony-vue/components/menu/product/views/Default.vue' /* webpackChunkName: "components/menu-product-views-default" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultSectionsAccountRewardsSectionChallengesBadgesSection = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountRewardsSection/ChallengesBadgesSection.vue' /* webpackChunkName: "components/account-default-sections-account-rewards-section-challenges-badges-section" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultSectionsAccountRewardsSectionChallengesSection = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountRewardsSection/ChallengesSection.vue' /* webpackChunkName: "components/account-default-sections-account-rewards-section-challenges-section" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultSectionsAccountRewardsSectionRewardOffers = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountRewardsSection/RewardOffers.vue' /* webpackChunkName: "components/account-default-sections-account-rewards-section-reward-offers" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultSectionsAccountRewardsSectionRewardRedeem = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountRewardsSection/RewardRedeem.vue' /* webpackChunkName: "components/account-default-sections-account-rewards-section-reward-redeem" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultSectionsAccountRewardsSectionRewardSubscriptions = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountRewardsSection/RewardSubscriptions.vue' /* webpackChunkName: "components/account-default-sections-account-rewards-section-reward-subscriptions" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultSectionsAccountRewardsSection = () => import('../../node_modules/@pleinair/bigcolony-vue/components/account/default/sections/AccountRewardsSection/index.vue' /* webpackChunkName: "components/account-default-sections-account-rewards-section" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
