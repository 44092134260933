
  import { mapState, mapMutations, mapActions } from 'vuex'


  export default {
    name: 'CustomSubmitPayment',
    computed: {
      ...mapState([ 'user', 'location' ]),
      ...mapState({
        loading: state => state.loading,
        cart: state => state.cart,
        paymentType: state => state.checkout.type,
        billing: state => state.checkout.billing,
        account: state => state.checkout.account,
        guestUser: state => state.checkout.guestUser,
        placement: state => state.checkout.placement,
      }),
      remainingBalance() {
        return this.$utils.remainingBalance()
      },
      disableSubmit() {
        if(this.loading) {
          return true
        } else if(this.paymentType) {
          return false
        } else if(this.remainingBalance) {
          return true
        } else {
          return false
        }
      },
      totalCost() {
        if(this.cart?.totals?.total) {
          return `(${this.$utils.currency(this.cart.totals.total)})`;
        }
      },
      payInStoreDisabled() {
        return this.disablePayInStore || !this.billing.find(b => b.type === 'payinstore') ? true : false
      },
    },
    methods: {
      ...mapMutations({
        setLoading: 'setLoading',
        setErrors: 'setErrors',
        setUser: 'setUser',
      }),
      ...mapActions({
        gatherPayments: 'checkout/gatherPayments',
        submitCart: 'checkout/submitCart',
      }),
      async handleSubmit() {
        this.setLoading(true)

        this.$utils.log('Searching for user...')
        if (!this.user && this.placement !== 'app') {
          if (this.guestUser) {
            this.$utils.log('Guest user found...')
            this.setUser(this.guestUser)
          } else {
            this.$utils.log('No user found...')
            this.setErrors(['Please enter your name, email, and phone number to checkout'])
          }
        }

        if (this.user && this.placement !== 'app') {
          if (
            !this.user.first_name ||
            !this.user.last_name ||
            !this.user.phone ||
            !this.user.email
          ) {
            this.setErrors(['Please enter your name, email, and phone number to checkout'])
          }
        }

        if (this.user || this.placement === 'app') {
          this.$utils.log('User found...')
          this.$utils.log('Getting payments...')
          const payments = await this.gatherPayments()
          this.$utils.log('Found payments...')
          if (this.paymentType === 'newcredit') {
            this.$utils.log('Submit new credit card payment...')
            this.$emit('submitPayment')
          } else {
            this.$utils.log('Submitting with saved payments/giftcards')
            const error = await this.submitCart(payments)
            if (error) {
              this.$utils.log('there was an error processing your payment, payment failed')
              this.setErrors([ error ])
              this.setLoading(false)
            }
          }
        }

        this.setLoading(false)
      }
    }
  }
