
import ScrollingDisplayItem from './ScrollingDisplayItem.vue';

export default {
  name: 'ScrollingDisplay',
  components: {
    ScrollingDisplayItem,
  },
  props: {
    blok: Object,
  },
};
