
import { mapState, mapActions } from 'vuex'
import NavigationIcon from '@pleinair/bigcolony-vue/components/_globals/vectors/NavigationIcon.vue'
import LocationPhoneInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/PhoneInfo.vue'
import LocationAddressInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/AddressInfo.vue'
import LocationOperationInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/OperationInfo.vue'
import VehicleInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/VehicleInfo.vue'

import PickupNewIcon from '@/components/globals/Icon/PickupNewIcon.vue'

export default {
  name: 'LocationSelected',
  components: {
    LocationOperationInfo,
    LocationAddressInfo,
    LocationPhoneInfo,
    NavigationIcon,
    VehicleInfo,
    PickupNewIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: () => { },
    },
    action: {
      type: Object,
      default: () => { },
    },
    config: {
      type: Object,
      default: () => {
        return {
          separator: '|',
          showServicesOnCard: false,
          showServicesOnOperation: true,
          locationPageAction: '',
          locationPageIcon: '',
          favoriteEnabled: false
        }
      }
    },
  },
  data() {
    return {
      showHandoffOrderMode: false,
    }
  },  
  computed: {
    ...mapState(['user', 'location', 'appInfo']),
    ...mapState({
      handoffMode: state => state.order.handoffMode,
      favLocs: state => state.members.favLocs,
      orderMode: state => state.order.orderMode,
    }),
    services() {
      if (this.config && this.config.showServicesOnCard && this.location && this.location.services) {
        const services = []
        if (this.location.services.delivery) {
          services.push({
            name: 'Delivery',
            iconName: 'delivery-icon',
            iconClasses: 'delivery-icon',
          })
        }
        if (this.location.services.pickup) {
          services.push({
            name: 'Pickup',
            iconName: 'pickup-icon',
            iconClasses: 'pickup-icon',
          })
        }
        if (this.location.services.curbside) {
          services.push({
            name: 'Curbside',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        if (this.location.services.drivethru) {
          services.push({
            name: 'Drive Thru',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        return services
      }

      return []
    },
    appHandoffModes() {
      return this.appInfo.order['handoff_modes']
    },
    appSubHandoffModes() {
      if (this.appHandoffModes && this.appHandoffModes.length > 0) {
        const handoffFound = this.appHandoffModes.find(handoff => handoff.sub_handoff_modes_keys && handoff.sub_handoff_modes_keys.includes(this.handoffMode))
        if (handoffFound && handoffFound.sub_handoff_modes && handoffFound.sub_handoff_modes.length > 0) {
          return handoffFound.sub_handoff_modes
        }
      }
      return []
    },
    locationHandoffModes() {
      if (this.location) {
        return this.$utils.locationHandoffModes(this.location, this.appHandoffModes)
      }

      return []
    },
    locationSubHandoffModes() {
      if (this.location) {
        return this.$utils.locationHandoffModes(this.location, this.appSubHandoffModes)
      }

      return []
    },
    timeSelectorOptions() {
      return this.appInfo.order['time_selector_options']
    },
    asap() {
      return this.timeSelectorOptions ? this.timeSelectorOptions.asap : { text: 'ASAP' }
    },
    later() {
      return this.timeSelectorOptions ? this.timeSelectorOptions.later : { text: 'Later' }
    },
    advance() {
      return this.timeSelectorOptions ? this.timeSelectorOptions.advance : { date: 'Select Date', time: 'Select Time'}
    },
  },

  methods: {
    ...mapActions([ 'trackEvent' ]),
    ...mapActions({
      saveFavLoc: 'members/saveFavLoc',
      deleteFavLoc: 'members/deleteFavLoc',
    }),
    async favorite() {
      const favLocFound = this.favLocs.find(l => l.id === this.location.id)
      if (favLocFound) {
        await this.deleteFavLoc(this.location.menu_id)
      } else {
        const response = await this.saveFavLoc(this.location.menu_id)
        if (response) {
          this.trackEvent({
            name: 'location_favorited',
            data: this.location,
            windowObject: window
          })
        }
      }
    },
  }
}
