
export default {
  name: 'AccordionSection',
  data() {
    return {
      internalShow: false
    }
  },
  props: {
    show: Boolean,
    title: String,
    section: String
  },
  computed: {
    hasSlotTitle() {
      return Boolean(this.$slots.title);
    }
  },
  methods: {
    toggleSection(section) {
      this.$emit('toggle', section)
      this.internalShow = !this.internalShow
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(show) {
        this.internalShow = Boolean(show)
      }
    }
  }
}
