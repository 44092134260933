
import moment from 'moment'

export default {
  name: 'DateSelect',
  props: {
    field: {
      type: Object,
      default: {
        name: '',
        label: '',
        type: 'text',
        value: '',
        valid: /^\d{4}-\d{2}-\d{2}$/,
        touched: false,
        error: null,
        min_age: false,
        errors: {
          invalid: 'Please enter a valid value.'
        }
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedMonth: null,
      selectedDay: null,
      selectedYear: null,
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
      days: {
        0: 31,
        1: 31,
        2: 28,
        3: 31,
        4: 28,
        5: 31,
        6: 30,
        7: 31,
        8: 31,
        9: 30,
        10: 31,
        11: 30,
        12: 31,
      }
    }
  },
  computed: {
    years() {
      let year = moment().format('YYYY')
      let years = []
      for (let x = parseInt(year); x > parseInt(year) - 100; x--) {
        years.push(x)
      }
      return years
    },
  },
  mounted() {
    if (this.field.value) {
      this.selectedYear = moment(this.field.value, 'YYYY-MM-DD').format('YYYY')
      this.selectedMonth = moment(this.field.value, 'YYYY-MM-DD').format('M')
      this.selectedDay = moment(this.field.value, 'YYYY-MM-DD').format('D')
    }
  },
  methods: {
    validate() {
      if (this.selectedMonth && this.selectedDay && this.selectedYear) {
        this.field.value = `${this.selectedYear}-${this.selectedMonth < 10 ? 0 : ''}${this.selectedMonth}-${this.selectedDay < 10 ? 0 : ''}${this.selectedDay}`
      } else {
        this.field.value = null
      }

      if (this.field.value) {
        let valid = this.field.value ? this.field.value.match(this.field.valid) : false

        if (this.field.min_age) {
          const ageDiff = moment(this.field.value).diff(moment(), 'milliseconds')
          const ageCalc = moment.duration(ageDiff)
          let age = -(ageCalc._data.years);
          valid = age >= 18 ? this.field.value.match(this.field.valid) : false
        }

        this.field.error = valid ? null : this.field.errors.invalid

        return valid
      }
    },
    handleKeyup(e) {
      if(this.field.touched) {
        this.validate()
      }
      if(this.field.max && this.field.value.length > this.field.max) {
        this.field.value = this.field.value.slice(0, -1)
      }
    },
    handleBlur(e) {
      this.field.touched = true
      this.validate()
    },
  }
}
