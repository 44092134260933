
// import { mapState } from 'vuex'
import AccordionSection from '@/components/common/AccordionSection';
export default {
  name: 'FaqSection',
  components: {
    AccordionSection
  },
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeSection: '',  
    }
  },
  computed: {
    // ...mapState(['user']),
  },
  methods: {
    toggleSection(section) {
      if(section.show) {
        section.show = false
      } else {
        section.show = true
      }
    }
  },
}
