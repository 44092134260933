
import { mapState, mapActions } from "vuex";
import institutionalMenu from "@/assets/menus/mobile-institutional-menu.json";
export default {
  name: "CustomMainNav",
  props: {
    logo: Object,
    menu: Array,
    subMenu: Array,
    config: Object,
  },
  data() {
    return {
      institutionalMenu
    };
  },
  computed: {
    ...mapState(["appInfo", "user", "showNav"]),
    ...mapState({
      rewards: (state) => state.members.rewards,
    }),
    points() {
      if (this.rewards && this.rewards.points) {
        return Math.round(this.rewards.points);
      }
      return 0;
    },
    progressOffset() {
      const pointsToOffsets = [
        { maxPoints: 50, offset: 800 },
        { maxPoints: 75, offset: 866 },
        { maxPoints: 150, offset: 766 },
        { maxPoints: 300, offset: 866 },
        { maxPoints: 600, offset: 900 },
        { maxPoints: 700, offset: 966 },
        { maxPoints: 800, offset: 1033 },
        { maxPoints: 900, offset: 1100 },
        { maxPoints: 1100, offset: 1200 },
        { maxPoints: 1199, offset: 1233 },
        { maxPoints: Infinity, offset: 1200 },
      ];

      const { offset } = pointsToOffsets.find(
        ({ maxPoints }) => this.points <= maxPoints
      );
      return offset;
    },
    progressIndicatorWidth() {
      // this function limits the size of the progress bar on iterations of 50 points
      let points = Math.min(1200, Math.ceil(this.points / 50) * 50);
      let progress = (points / this.progressOffset) * 100;
      return `${progress < 100 ? progress : 100}%`;
    },
    progressRemainingWidth() {
      let progress = parseFloat(this.progressIndicatorWidth);
      return `${100 - progress}%`;
    },
    mainMenu() {
      // remove "Account" from menu if user isn't logged in
      return this.menu.filter((item) => {
        if (item.title === "Account") {
          return this.user;
        }
        return true;
      });
    }
  },
  methods: {
    ...mapActions([ 'logoutUser' ]),
    async logout() {
      const result = await this.logoutUser()
      if(result) {
        this.toggleNav();
        this.$router.push('/account/signin');
      }
    },
    toggleNav() {
      document.body.style.overflow = !this.showNav ? "hidden" : "auto";
      this.$store.commit("setShowNav", !this.showNav);
    },
    runCallback(callback) {
      this.toggleNav();
      return callback();
    },
    buttonClass(name) {
      if (name) {
        return this.$utils.kebab(name);
      }
      return "";
    },
  },
};
