
import { mapState, mapActions } from 'vuex';

export default {
  name: 'OrderAgain',
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['user', 'orderHistory']),
    lastOrders() {
      if(this.orderHistory) {
        return this.orderHistory.slice(0, 3);
      }
    }
  },
  methods: {
    ...mapActions(['getOrderHistory']),
  },
  async beforeMount() {
    await this.getOrderHistory();
  },
}
