
import Hero from '@/components/storyblok/Hero.vue'

export default {
  name: 'HeroSlider',
  components: { Hero },
  props: {
    blok: Object,
  },
  data() {
    return {
      currentIndex: 0,
      isTransitioning: false,
      intervalId: null,
      touchStartX: 0,
      touchEndX: 0,
    }
  },
  computed: {
    slides() {
      return this.blok?.Slides || []
    },
    clonedSlides() {
      if (this.slides.length > 0) {
        return [this.slides[this.slides.length - 1], ...this.slides, this.slides[0]]
      }
      return []
    },
    wrapperStyle() {
      return {
        transform: `translateX(-${this.currentIndex * 100}%)`,
        transition: this.isTransitioning ? 'transform 0.5s ease' : 'none'
      }
    }
  },
  methods: {
    handleTransitionEnd() {
      this.isTransitioning = false
      if (this.currentIndex === 0) {
        this.currentIndex = this.slides.length
      } else if (this.currentIndex === this.slides.length + 1) {
        this.currentIndex = 1
      }
    },
    prevSlide() {
      if (!this.isTransitioning) {
        this.isTransitioning = true
        this.currentIndex--
      }
    },
    nextSlide() {
      if (!this.isTransitioning) {
        this.isTransitioning = true
        this.currentIndex++
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX
    },
    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX
    },
    handleTouchEnd() {
      if (this.touchStartX - this.touchEndX > 50) {
        this.nextSlide()
      } else if (this.touchStartX - this.touchEndX < -50) {
        this.prevSlide()
      }
    },
    startAutoSlide() {
      this.intervalId = setInterval(() => {
        this.nextSlide()
      }, 5000)
    },
    stopAutoSlide() {
      clearInterval(this.intervalId)
    }
  },
  mounted() {
    this.startAutoSlide()
  },
  beforeDestroy() {
    this.stopAutoSlide()
  }
}
