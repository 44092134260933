
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'ProductModCounter',
  props: {
    onChange: {
      type: Function,
      default: () => {}
    },
    quantities: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      quantity: null,
      error: false,
    }
  },
  mounted() {
    // Work around for updating modifer quanity for counters when editing a product
    let overwrite = null
    let choices = []
    if (this.choices?.length) {
      this.choices.map(c => {
        if (this.data.choice_id === c.choice_id) {
          this.updateQuantity(1) // Update counter default quantity if editing product
          overwrite = this.$utils.copy(c) // save latest example as choice
          overwrite.quantity = this.quantity // update saved choice with correct quantity
        } else {
          choices.push(c) // push existing choice to new choice array
        }
      })
      if (overwrite) {
        choices.push(overwrite) // push saved choice with correct quantity
        this.setChoices(choices) // overwrite choices state
      }
    }
  },
  computed: {
    ...mapState({
      choices: state => state.product.choices,
    })
  },
  methods: {
    ...mapMutations({
      setChoices: 'product/setChoices',
    }),
    updateQuantity(num) {
      if (this.quantity) {
        if (num > 0) { // if num is positive
          if (this.quantity < this.quantities.max) { // add num to quantity if the max is not reached
            this.quantity += num
          }
        } else { // if num is negative
          if (this.quantity === this.quantities.min) { // set to zero if already at min
            this.quantity = null
          } else { // remove num from quantity if above the min
            this.quantity += num
          }
        }
      } else { // set quantity to min if no quantity is set and num is positive
        if (num > 0) {
          this.quantity = this.quantities.min || 1
        }
      }
      this.onChange(this.data, this.data.choice_id, this.quantity)
    },
  }
}
