
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'ProductRecipient',
  computed: {
    ...mapState({
      product: state => state.product,
    }),
    recipient: {
      get () {
        return this.product.recipient
      },
      set (value) {
        this.setRecipient(value)
      }
    }
  },
  methods: {
    ...mapMutations({
      setRecipient: 'product/setRecipient',
    }),
  }
}
