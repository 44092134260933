
  import { mapActions, mapMutations, mapState } from 'vuex'
  import moment from 'moment'

  export default {
    name: 'Offer',
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        showMore: false,
        descriptionLimit: 130,
      }
    },
    computed: {
      ...mapState([ 'appInfo' ]),
      expiryDisplayType() {
        // Possible values: 'expiresInDays', 'validThruDate'
        return this.appInfo?.offer?.expiryDisplayType || 'validThruDate'
      },
      formattedExpiryDate() {
        if (!this.data.expires) return null

        if (this.expiryDisplayType === "expiresInDays") {
          const now = moment();
          const expiryDate = moment(this.data.expires, 'YYYYMMDD HH:mm');
          const diffDays = expiryDate.diff(now, 'days');
          return diffDays
        }

        const dateObject = this.data.expires
        return moment(dateObject.substring(0, dateObject.indexOf(' '))).format('MM/DD/YYYY')
      },
      shortDescription() {
        return this.data.description.length > this.descriptionLimit 
             ? this.data.description.substring(0, this.descriptionLimit) + '...' 
             : this.data.description
      }
    },
    methods: {
      ...mapActions([ 'getCart' ]),
      ...mapMutations([ 'setLoading', 'setErrors']),
      ...mapActions({
        validateCart: 'checkout/validateCart',
        getCartRewards: 'checkout/getCartRewards',
        applyCartReward: 'checkout/applyCartReward',
        removeCartReward: 'checkout/removeCartReward',
      }),
      async applyReward(r) {
        const rewardResp = await this.applyCartReward(r)
        if (!rewardResp) return

        // check if the user has the applicable products or is ordering from the correct location
        this.setLoading(true)
        const invalidCart = await this.validateCart()
        if (invalidCart) {
          if (invalidCart.toLowerCase().includes('selected reward')) {
            this.setErrors('Your reward is not valid for this order. Please confirm you have the applicable products or are ordering from the correct location and try again later.')
          } else {
            this.setErrors(invalidCart)
          }

          // remove reward
          await this.removeReward(r)
        } else {
          await this.getCartRewards()
        }
        this.setLoading(false)
      },
      async removeReward(r) {
        await this.removeCartReward(r)  
        this.setLoading(true)
        const invalidCart = await this.validateCart()   
        if (invalidCart) {
          this.setErrors(invalidCart)
        }
        this.setLoading(false)
      },
    },
  }
