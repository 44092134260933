import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31186d84 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _484494f6 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _16ddd532 = () => interopDefault(import('../pages/locations/index.vue' /* webpackChunkName: "pages/locations/index" */))
const _2c62ff52 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _c67311ce = () => interopDefault(import('../pages/typography.vue' /* webpackChunkName: "pages/typography" */))
const _40a509fa = () => interopDefault(import('../pages/account/confirmed.vue' /* webpackChunkName: "pages/account/confirmed" */))
const _f79da9a2 = () => interopDefault(import('../pages/account/forgot.vue' /* webpackChunkName: "pages/account/forgot" */))
const _17f8d1a2 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _635e602e = () => interopDefault(import('../pages/account/signin.vue' /* webpackChunkName: "pages/account/signin" */))
const _77f4b6a4 = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _117e1744 = () => interopDefault(import('../pages/app/checkout.vue' /* webpackChunkName: "pages/app/checkout" */))
const _7c3e793c = () => interopDefault(import('../pages/app/subscribe.vue' /* webpackChunkName: "pages/app/subscribe" */))
const _69c9a420 = () => interopDefault(import('../pages/app/testcheckout.vue' /* webpackChunkName: "pages/app/testcheckout" */))
const _8081fc14 = () => interopDefault(import('../pages/locations/all.vue' /* webpackChunkName: "pages/locations/all" */))
const _c7a1a85e = () => interopDefault(import('../pages/order/checkout.vue' /* webpackChunkName: "pages/order/checkout" */))
const _0d3c9bf6 = () => interopDefault(import('../pages/order/confirm/_oid.vue' /* webpackChunkName: "pages/order/confirm/_oid" */))
const _63883c82 = () => interopDefault(import('../pages/menu/_mid/index.vue' /* webpackChunkName: "pages/menu/_mid/index" */))
const _04882f20 = () => interopDefault(import('../pages/menu/_mid/category/_cid.vue' /* webpackChunkName: "pages/menu/_mid/category/_cid" */))
const _ac124d28 = () => interopDefault(import('../pages/menu/_mid/product/_pid.vue' /* webpackChunkName: "pages/menu/_mid/product/_pid" */))
const _991ffde8 = () => interopDefault(import('../pages/locations/_state/_city/_address.vue' /* webpackChunkName: "pages/locations/_state/_city/_address" */))
const _b186cf36 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _31186d84,
    name: "404"
  }, {
    path: "/account",
    component: _484494f6,
    name: "account"
  }, {
    path: "/locations",
    component: _16ddd532,
    name: "locations"
  }, {
    path: "/order",
    component: _2c62ff52,
    name: "order"
  }, {
    path: "/typography",
    component: _c67311ce,
    name: "typography"
  }, {
    path: "/account/confirmed",
    component: _40a509fa,
    name: "account-confirmed"
  }, {
    path: "/account/forgot",
    component: _f79da9a2,
    name: "account-forgot"
  }, {
    path: "/account/settings",
    component: _17f8d1a2,
    name: "account-settings"
  }, {
    path: "/account/signin",
    component: _635e602e,
    name: "account-signin"
  }, {
    path: "/account/signup",
    component: _77f4b6a4,
    name: "account-signup"
  }, {
    path: "/app/checkout",
    component: _117e1744,
    name: "app-checkout"
  }, {
    path: "/app/subscribe",
    component: _7c3e793c,
    name: "app-subscribe"
  }, {
    path: "/app/testcheckout",
    component: _69c9a420,
    name: "app-testcheckout"
  }, {
    path: "/locations/all",
    component: _8081fc14,
    name: "locations-all"
  }, {
    path: "/order/checkout",
    component: _c7a1a85e,
    name: "order-checkout"
  }, {
    path: "/order/confirm/:oid?",
    component: _0d3c9bf6,
    name: "order-confirm-oid"
  }, {
    path: "/menu/:mid",
    component: _63883c82,
    name: "menu-mid"
  }, {
    path: "/menu/:mid?/category/:cid?",
    component: _04882f20,
    name: "menu-mid-category-cid"
  }, {
    path: "/menu/:mid?/product/:pid?",
    component: _ac124d28,
    name: "menu-mid-product-pid"
  }, {
    path: "/locations/:state/:city?/:address?",
    component: _991ffde8,
    name: "locations-state-city-address"
  }, {
    path: "/*",
    component: _b186cf36,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
