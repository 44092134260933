
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'OrderCard',
  props: {
    order: Object,
  },
  data() {
    return {
      showTotals: false,
    }
  },
  computed: {
    ...mapState(['location', 'cart']),
    ...mapState({
      locs: state => state.locations
    }),
    hasSlotDate() {
      return false
    },
    hasSlotToggle() {
      return false
    },
    detailIcon() {
      switch (this.order.handoff) {
        case 'pickup':
          return 'pickup-icon'
        case 'delivery':
          return 'delivery-icon'
        case 'curbside':
          return 'curbside-icon'
        default: break;
      }
    },
    showItems() {
      const items = !this.showTotals ? 2 : this.order.products.length
      return this.order.products.slice(0, items);
    }
  },
  methods: {
    ...mapMutations(['setShowCart', 'setCart', 'setLoading', 'setErrors', 'setLocation',]),
    ...mapActions(['sendRequest', 'viewCart', 'changeLocation']),
    getDate(date) {
      if (date) {
        return moment(date, 'YYYYMMDD HH:mm').format('ddd MMM D, YYYY');
      }
    },
    getChoices(items) {
      const limiter = 3;
      let itemsHtml = items.slice(0, limiter).map(item => `<span>${item.name}</span>`).join(' • ');
      if (items.length > limiter) {
        itemsHtml += ` <span class="more-choices">+${items.length - limiter} items</span>`;
      }
      return itemsHtml;
    },
    formatPrice(value) {
      if (typeof value === 'number' && isFinite(value)) {
        return `$${value.toFixed(2)}`
      }
    },
    setLocationsByMenuId(menu_id) {
      const locationsByMenuId = {}
      const all = this.locs.all
      for (let i = 0; i < all.length; i++) {
        let location = all[i]
        let cities = location.cities
        for (let j = 0; j < cities.length; j++) {
          let city = cities[j]
          let cityLocations = city.locations
          for (let k = 0; k < cityLocations.length; k++) {
            let loc = cityLocations[k]
            locationsByMenuId[loc.menu_id] = loc
          }
        }
      }
      this.setLocation(locationsByMenuId[menu_id])
    },
    async reorder(order) {
      if (!this.location || !this.location.address) {
        this.setLocationsByMenuId(order.menu_id)
      }

      if (order.id) {
        this.setLoading(true)
        const config = {
          method: 'post',
          url: 'orders',
          data: {
            id: order.id
          }
        }
        const response = await this.sendRequest(config)
        if (response.status === 'ok') {
          let cart = { ...response.data }
          if (cart.menu_id !== this.location.menu_id) {
            const message = {
              message: `Changing your location will clear your cart. Do you want to continue?`,
              actions: [
                {
                  type: 'button',
                  title: 'Change location anyway',
                  class: 'primary',
                  callback: this.setLocationsByMenuId(cart.menu_id),
                }
              ],
            }
            await this.setErrors(message)
          }
          this.setCart(cart)
          document.body.style.overflow = 'hidden'
          this.$router.push('/order/checkout')
        } else {
          this.setErrors(response.message)
        }
        this.setLoading(false)
      }
    },
    goToOrder(id) {
      this.$router.push(`/order/confirm/${id}`);
    }
  }
}
