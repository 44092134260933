
import { mapState } from 'vuex'

import SingleProduct from '@pleinair/bigcolony-vue/components/common/SingleProduct/index.vue'
import OrderHeader from '@pleinair/bigcolony-vue/components/common/OrderHeader/index.vue'
import CustomLocationSelected from '@/components/common/CustomLocationSelected.vue'
import CustomLocationCard from '@/components/common/CustomLocationCard.vue'
import LocationSelectedDelivery from '@pleinair/bigcolony-vue/components/common/LocationSelectedDelivery.vue' 
import HandoffModeSelector from '@pleinair/bigcolony-vue/components/common/HandoffModeSelector/index.vue'
import OrderTimePicker from '@pleinair/bigcolony-vue/components/common/OrderTimePicker.vue'
import Icon from '@pleinair/bigcolony-vue/components/common/Icon/index.vue'

export default {
  name: 'CheckoutHeader',
  components: {
    OrderHeader,
    SingleProduct,
    OrderTimePicker,
    CustomLocationSelected,
    CustomLocationCard,
    LocationSelectedDelivery,
    HandoffModeSelector,
    Icon,
  },
  props: {
    customHeader: Boolean,
    customHeaderTitle: String,
    customHeaderLeftAction: Object,
    customHeaderRightAction: Object,
    customLocationIcon: Object,
    config: Object,
  },
  computed: {
    ...mapState([ 'location', 'appInfo' ]),
    ...mapState({
      order: state => state.order,
      handoffMode: state => state.order.handoffMode,
    }),
    isXLarge() {
      return this.$mq == 'sm' || this.$mq == 'md' || this.$mq == 'lg' ? false : true
    },
    isMobile() {
      return this.$mq == 'sm' || this.$mq == 'md' ? true : false
    },
    locationSelectedTitle() {
      if (this.isMobile) {
        return this.location.name
      }
      if (this.location) {
        return this.config?.headerInfo?.title || `Your order from ${this.location.name}`;
      }
      return ''
    },
    locationSelectedIcon() {
      if (this.isMobile) {
        return { show: false }
      }

      return {
        show: true,
        name: 'shopping-bag-icon',
        classes: 'icon large black'
      }
    },
    appHandoffModes() {
      return this.appInfo.order['handoff_modes']
    },
    locationHandoffModes() {
      if (this.location) {
        return this.$utils.locationHandoffModes(this.location, this.appHandoffModes)
      }

      return []
    },
    appSubHandoffModes() {
      if (this.appHandoffModes && this.appHandoffModes.length > 0) {
        const handoffFound = this.appHandoffModes.find(handoff => handoff.sub_handoff_modes_keys && handoff.sub_handoff_modes_keys.includes(this.handoffMode))
        if (handoffFound && handoffFound.sub_handoff_modes && handoffFound.sub_handoff_modes.length > 0) {
          return handoffFound.sub_handoff_modes
        }
      }
      return []
    },
    locationSubHandoffModes() {
      if (this.location) {
        return this.$utils.locationHandoffModes(this.location, this.appSubHandoffModes)
      }

      return []
    },
    isLocationSelectedDelivery() {
      return this.order?.handoffMode === 'delivery' && this.appInfo.checkout?.show_location_delivery
    }
  },
}
