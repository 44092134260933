
export default {
  name: 'InputDefault',
  props: {
    field: {
      type: Object,
      default: {
        name: '',
        label: '',
        type: 'text',
        value: '',
        // valid: /.*?/,
        touched: false,
        error: null,
        errors: {
          invalid: 'Please enter a valid value.'
        }
      }
    },
    type: String,
    loading: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    // validate() {
    //   const valid = this.field.value && this.field.value.replace
    //     ? this.field.value.replace(/[- )(]/g,'').match(this.field.valid)
    //     : false
    //   this.field.error = valid ? null : this.field.errors.invalid
    //   return valid
    // },
    handleKeyup(e) {
      if(this.field.touched) {
        this.validate(this.field)
      }
      if(this.field.max && this.field.value.length > this.field.max) {
        this.field.value = this.field.value.slice(0, -1)
      }
    },
    handleBlur(e) {
      this.field.touched = true
      this.validate(this.field)
    }
  }
}
