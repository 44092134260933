
  import { mapState, mapMutations, mapActions } from 'vuex';
  import CustomMobileNav from './CustomMobileNav.vue';
  import isMobile from '@/mixins/is-mobile';
  import Logos from '@/assets/icons/icons.json';
  import MainMenu from '@/assets/menus/main.json';
  import MobileMenu from '@/assets/menus/mobile-menu.json';
  import MobileSubmenu from '@/assets/menus/mobile-submenu.json';

  export default {
    name: 'Header',
    mixins: [isMobile],
    components: {
      CustomMobileNav,
    },
    props: {
      config: Object,
      locationIcon: Object,
      chevron: Object,
      mobileMenuConfig: Object,
    },
    data() {
      return {
        handoffModes: [],
        logos: Logos,
        menu: MainMenu,
        mobileMenu: MobileMenu,
        mobileSubmenu: MobileSubmenu,
      };
    },
    computed: {
      ...mapState(['appInfo', 'user', 'location', 'showNav', 'order', 'cart']),
      ...mapState({
        rewards: (state) => state.members.rewards,
      }),
      appHandoffModes() {
        return this.appInfo.order['handoff_modes_main_nav'];
      },
      locationHandoffModes() {
        if (this.location) {
          return this.$utils.locationHandoffModes(
            this.location,
            this.appHandoffModes
          );
        }
      },
      hasCartProducts() {
        return this.cart && this.cart.products?.length > 0;
      },
      checkSize() {
        if (
          this.isMobile &&
          this.config &&
          this.config.greetings &&
          this.points > this.config.greetings.checkPointsForSize
        ) {
          return false;
        }
        return true;
      },
      points() {
        if (this.rewards && this.rewards.points) {
          return this.rewards.points.toLocaleString('en-US');
        }
        return 0;
      },
      pointsText() {
        return this.points === 1 ? 'Point' : 'Points'
      },
      headerLogo() {
        return this.isMobile ? this.logos?.mobile_logo : this.logos?.logo;
      },
    },
    methods: {
      ...mapMutations({
        setHandoffMode: 'order/setHandoffMode',
      }),
      ...mapActions(['changeLocation']),
      goToReserveTable() {
        this.setHandoffMode('reservation');
        this.$nuxt.$router.push('/locations');
      },
      changeLocations(){
        this.changeLocation(null)
        this.$nuxt.$router.push('/locations')
      }
    },
  };
