
import { mapState } from 'vuex';
import { CustomButton } from '@/components';

export default {
  name: 'banner',
  components: { CustomButton },
  props: {
    blok: {
      type: Object,
      default: () => { }
    },
  },
  computed: {
    ...mapState(['user']),
    detailClasses() {
      return [
        this.blok?.top_detail ? `top-detail-${this.blok?.top_detail}` : '',
        this.blok?.bottom_detail ? `bottom-detail-${this.blok?.bottom_detail}` : '',
      ]
    },
    hasBgImage() {
      return this.blok?.bg_image?.filename
    },
    hasBgImageMobile() {
      return this.blok?.bg_image_mobile?.filename
    },
    isDescriptionRichText() {
      return typeof this.blok.description === 'object'
    },
    showBanner() {
      const show = this.blok?.show || 'everyone';
      
      // Only Signed In users
      if(show === 'users-only') {
        return Boolean(this.user);
      }
      // Only for Not Signed In users
      else
      if(show === 'guests-only') {
        return Boolean(!this.user);
      }
      // Everyone
      else {
        return true;
      }
    }
  },
}
