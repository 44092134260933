import { render, staticRenderFns } from "./CustomMobileNav.vue?vue&type=template&id=70f2d952&"
import script from "./CustomMobileNav.vue?vue&type=script&lang=js&"
export * from "./CustomMobileNav.vue?vue&type=script&lang=js&"
import style0 from "./CustomMobileNav.vue?vue&type=style&index=0&id=70f2d952&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIcon: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/Icon/index.vue').default,GlobalsLayoutHeaderNavLink: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/_globals/layout/header/NavLink.vue').default})
