
  const Sizes = ['small', 'large', '', undefined, null]
  const Types = ['primary', 'secondary', 'outline', 'neutral', 'link', '', undefined, null]
  
  export default {
    name: 'CustomButton',
    props: {
      blok: Object,
      type: {
        type: String,
        validator: (value) => Types.includes(value),
        default: 'primary'
      },
      size: {
        type: String,
        validator: (value) => Sizes.includes(value),
      },
      icon: String,
      disabled: Boolean,
      link: {
        type: Boolean,
        default: false
      },
      counter: [Number, String],
      to: String,
      label: String,
      target: String,
      param: String,
    },
    computed: {
      isMobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      isLink() {
        return this.link || this.blok?.variant === 'link'
      },
      cachedUrl() {
        let url = this.blok?.link?.cached_url || this.blok?.link?.url;
        if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'http://' + url;
        }
        return url;
      },
      btnClasses() {
        const size = this.size || this.isMobile ? 'small' : 'large'
        
        let type = this.blok?.variant || this.type
        
        if(this.isLink) {
          type += ' link'
        }
        
        return [
          'btn',
          size,
          type,
          {'icon-only': this.icon && !this.label}
        ]
      },
      isExternalLink() {
        return this.blok?.link?.cached_url && this.blok.link.linktype === 'url'
      },
      linkTarget() {
        return this.target || this.blok?.link?.target || '_self'
      },
    },
    methods: {
      handleClick(ev) {
        this.$emit('click', ev);
        
        if (this.isExternalLink) return;
        
        if(this.to) {
          this.$nuxt.$router.push(this.to)
        }
        else if (this.blok?.link?.cached_url) {
          const param = this.param ? `?${this.param}` : ''
          this.$nuxt.$router.push('/' + this.blok.link.cached_url + param)
        }     
      }
    }
  }
