
  import { mapState } from 'vuex'
  import Offer from './Offer.vue'
  
  export default {
    name: 'CheckoutOffers',
    components: { 
      Offer 
    },
    props: {
      rewards: Array,
      config: Object,
    },
    data() {
      return {
        scroll: 0,
        innerWidth: 0,
        fullWidth: 0,
        cardWidth: 0,
      }
    },
    mounted() {
      setTimeout(() => {
        const $section = document.getElementById('checkout-offers')
        if ($section) {
          this.innerWidth = $section.scrollWidth
        }
        const $items = document.getElementById('checkout-offers-list')
        if ($items) {
          this.fullWidth = $items.scrollWidth
          if ($items.children && $items.children.length > 0) {
            this.cardWidth = $items.children[0].offsetWidth
          }
        }
      }, 500)
    },
    computed: {
      ...mapState([ 'user', 'appInfo' ]),
      ...mapState({
        rewards_points: state => state.members.rewards.points,
      }),
      showNavigation() {
        if (
          this.fullWidth > this.innerWidth &&
          this.config?.navigationArrows?.rightIconName &&
          this.config?.navigationArrows?.leftIconName
        ) {
          return true
        }
        return false
      },
    },
    methods: {
      leftScroll() {
        document.getElementById('checkout-offers-list').scrollLeft += -this.cardWidth
      },
      rightScroll() {
        document.getElementById('checkout-offers-list').scrollLeft += this.cardWidth
      },
      updateScroll() {
        const $items = document.getElementById('checkout-offers-list')
        if ($items) {
          this.scroll = $items.scrollLeft
        }
      },
    }
  }
